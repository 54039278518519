import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./plugins/vue-i18n";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./assets/style/components.scss";
import "./assets/style/style.scss";
import { store } from "@/store/store";
import Vue3Lottie from "vue3-lottie";
import VueGtag from "vue-gtag";

const app = createApp(App);
app.use(VueGtag, {
  config: { id: "G-K5YMR3EETG" },
});
app.use(Vue3Lottie);
app.use(ElementPlus);
app.use(router);
app.use(store);
app.use(i18n);
app.mount("#app");
