<template>
  <div class="relative nav-container">
    <div id="nav" class="nav-bar flex-center">
      <div>
        <img
          src="@/assets/image/logo.png"
          style="width: 13rem"
          alt="samitivej image"
        />
      </div>

      <div class="ml-auto flex-center">
        <img
          v-if="lang == 'en'"
          src="@/assets/image/eng-lang.png"
          style="width: 3.4rem"
          alt="eng lang"
        />
        <img
          v-else
          src="@/assets/image/thai-lang.png"
          alt="thai lang"
          style="width: 3.4rem"
        />
        <el-select
          v-model="lang"
          class="m-2 ml-12"
          placeholder="Select"
          style="width: 15.8rem"
        >
          <el-option key="en" :label="$t('english')" value="en"> </el-option>
          <el-option key="th" :label="$t('thai')" value="th"> </el-option>
        </el-select>
      </div>
    </div>
    <div class="step-box center">
      <div class="step-progress">
        <div
          v-for="id in returnMockArray(step)"
          :key="id"
          class="step-bar fs-2"
        >
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue3-i18n";

export default defineComponent({
  name: "Navbar",
  props: {
    fullStep: Number,
    step: Number,
  },
  setup() {
    const i18n = useI18n();
    const setLocale = (lang) => {
      i18n.setLocale(lang);
    };

    return {
      setLocale,
    };
  },
  data() {
    return {
      lang: "th",
    };
  },
  mounted() {
    this.lang = !this.$route.query.lang ? this.lang : this.$route.query.lang;
    console.log(this.step, this.fullStep);
  },
  watch: {
    lang(value) {
      this.setLocale(value);
      this.$emit("changeLang", value);
    },
  },

  methods: {
    returnMockArray(value) {
      let arr = [];
      for (let i = 0; i < value; i++) {
        arr.push("mock_step" + i);
      }
      return arr;
    },
    showStepValue() {
      let step = "";
      switch (this.step) {
        case "question_sex":
          step = 1;
          break;
        case "question_career":
          step = 2;
          break;
        case "question_body_details":
          step = 3;
          break;
        case "question_health":
          step = 4;
          break;
        case "question_behavior":
          step = 5;
          break;
        case "question_disease":
          step = 6;
          break;
        case "question_disease_family":
          step = 7;
          break;
        // case "question_activity":
        //   step = 8;
        //   break;
        case "question_exercise_week":
          step = 8;
          break;
        // case "question_sports":
        //   step = 9;
        //   break;
      }
      return step;
    },
  },
});
</script>

<style lang="scss" scoped>
.nav-container {
  height: 5rem;
  width: 100%;

  .nav-bar {
    display: fixed;
    padding: 2.3rem 2rem 0;

    @media only screen and (min-width: 500px) {
      padding: 2.3rem 2.6rem 0;
    }
  }

  .step-box {
    // display: fixed;
    // padding: 1.5 rem 1rem 0;
    .step-progress {
      width: 90%;
      height: 0.3rem;
      margin: auto;
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(10, calc(11% - (0.4rem / 10)));
      gap: 0.4rem;
      background-color: #f3f3e7;

      .step-bar {
        height: 0.3rem;
        width: 100%;
        background: #6db557;
      }
    }
  }
}

.el-input__inner {
  background-color: #f3f3e7;
}

.el-select .el-input__inner {
  background-color: #f3f3e7;
  cursor: pointer;
  display: inline-flex;
}
</style>
