import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/booking1",
    name: "Booking1",
    component: () =>
      import(/* webpackChunkName: "booking1" */ "../views/booking1.vue"),
  },
  // {
  //   path: "/booking-prerequisite",
  //   name: "BookingPrerequisite",
  //   component: () =>
  //     import(/* webpackChunkName: "BookingPrerequisite" */ "../views/bookingPrerequisite.vue"),
  // },
  {
    path: "/booking",
    name: "Booking",
    component: () =>
      import(/* webpackChunkName: "Booking" */ "../views/booking.vue"),
  },
  {
    path: "/booking3",
    name: "Booking3",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/booking3.vue"),
  },
  {
    path: "/booking-payment",
    name: "BookingPayment",
    component: () =>
      import(
        /* webpackChunkName: "BookingPayment" */ "../views/BookingPayment.vue"
      ),
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
  },
  {
    path: "/signin",
    name: "Signin",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Signin.vue"),
  },
  {
    path: "/changepass",
    name: "ChangPass",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/ChangePass.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Forgot.vue"),
  },
  {
    path: "/select-payment",
    name: "SelectPayment",
    component: () =>
      import(
        /* webpackChunkName: "SelectPayment" */ "../views/SelectPayment.vue"
      ),
  },
  {
    path: "/credit-card-payment",
    name: "CreditCardPayment",
    component: () =>
      import(
        /* webpackChunkName: "PaymentPage" */ "../views/CreditCardPayment.vue"
      ),
  },
  {
    path: "/qr-payment",
    name: "QRPayment",
    component: () =>
      import(/* webpackChunkName: "PaymentPage2" */ "../views/QRPayment.vue"),
  },
  {
    path: "/payment-success",
    name: "PaymentSuccess",
    component: () =>
      import(
        /* webpackChunkName: "PaymentSuccess" */ "../views/PaymentSuccess.vue"
      ),
  },
  {
    path: "/payment-waiting-credit",
    name: "PaymentWaitingCredit",
    component: () =>
      import(
        /* webpackChunkName: "PaymentWaitingCredit" */ "../views/PaymentWaitingCredit.vue"
      ),
  },
  {
    path: "/payment-credit-success",
    name: "PaymentCreditCardSuccess",
    component: () =>
      import(
        /* webpackChunkName: "PaymentCreditCardSuccess" */ "../views/PaymentCreditCardSuccess.vue"
      ),
  },
  {
    path: "/sth",
    name: "STHHome",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/sth/Home.vue"),
  },
  {
    path: "/sth/booking",
    name: "STHBooking",
    component: () =>
      import(/* webpackChunkName: "booking" */ "../views/sth/booking.vue"),
  },
  {
    path: "/sth/register",
    name: "STHRegister",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/sth/Register.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
