import { createI18n } from "vue3-i18n";

const messages = {
  en: {
    // nav
    "": "",
    thai: "ไทย",
    english: "English",
    // home
    home: "Find the health package that's right for you",
    homeDesc:
      "For accurate guidance, please answer health questions truthfully.",
    start: "Let's Start",
    already: "I already have an account.",
    agreeUsingData: "I consent to",
    agreeUsingData2:
      " the use of my information for data processing and the presentation of personalized health packages and services ",
    agreeUsingData3: "by the hospital",
    agreeTermandPrivacy: "I agree to",
    TermAndCond: "Samitivej Hospital Terms & Conditions",
    and: "and ",
    privacyPolicy: "Privacy policy",
    consent:
      "Samitivej Public Company Limited would like to ask for your consent to keep, collect, use, and disclose your personal data and any other information for purposes of marketing and communication, marketing advertisements, sales, special offerings, news, public relations promotions, and presentations of the products and service under the scope of Digital Total Health Solution Service provided the by Samitivej Hospital.",
    close: "Close",
    hospital: "Hospital",
    pickDate: "Appointment Date",
    // sigin
    email: "Email",
    password: "Password",
    confirmpass: "Confirm Password",
    register: "Register",
    forgot: "Forget password",
    signin: "Signin",
    loading: "Loading",
    codeMismatchException: "Invalid verification code provided",
    notAuthorizedException: "Incorrect username or password",
    userNotFoundException: "User not found",
    userNotConfirmedException: "User registration not confirmed",
    limitExceededException:
      "Attempt limit exceeded, please try after some time",
    error: "An error has occured!",
    requiredPass: "Password is required",
    more8TO20Pass: "Password must be 8-20 characters",
    numberPass: "Password must contain at least 1 number",
    lowerPass: "Password must contain at least 1 lower case letter",
    upperPass: "Password must contain at least 1 upper case letter",
    SpecialCharPass:
      'Password must contain at least 1 special character (!@#$%^&*")',
    dontMatchPass: "Password did not match",
    // Forgot
    send: "Send",
    code: "Confirmation Code",
    newPassword: "New Password",
    // ChangePass
    PassSuggest:
      "Password must be 8-20 characters and contain uppercase, lowercase, number, and special character.",
    // Booking
    or: " or ",
    recommend: "Recommend",
    selectAll: "Select all",
    seemore: "See more",
    seeDetail: "See Detail",
    notIncludeDoctor: "(Prices do not include doctor's fee)",
    hideDetail: "Hide Detail",
    unSelectAll: "Unselect all",
    hello: "Hello",
    riskscore: "Your Risk",
    pleasSelect: "Please select ",
    pleasSelect2: " before selelct this package",
    // pleaseUnselect: "Have some details same as another package",
    pleaseUnselect:
      "This package has included in another package that you already selected.",
    pleaseUnselect2: "please only select this package",
    selectHospitalHeader:
      "Please select a hospital to view recommended health programs",
    choosehospital: "Choose Hospital",
    choosehospital2: "Reserve a health check-up date",
    sukhumvit: "Samitivej Sukhumvit",
    srinakarin: "Samitivej Srinakarin",
    sriracha: "Samitivej Sriracha",
    thonburi: "Samitivej Thonburi",
    chonburi: "Samitivej Chonburi",
    chinatown: "Samitivej Chinatown",
    appdate: "Date",
    summary: "Health Program Summary",
    coupon: "Coupon Code",
    subtotal: "Selected Program",
    showSamePackage:
      "* Since it is a repeated package, it is merely a display of details for customers' knowledge. The system does not charge duplicate fees.",
    total: "Total",
    checkout: "Book",
    pay: "Pay",
    paymentMethod: "Payment method",
    qrCode: "QR Code",
    creditCard: "Credit/Debit",
    yourrisk: "Your Risk Level",
    cvdlevel1: "Low Risk",
    cvdlevel2: "Moderate Risk",
    cvdlevel3: "High Risk",
    cvdlevel4: "High Risk",
    cvdlevel5: "High Risk",
    dmlevel1: "Low Risk",
    dmlevel2: "Moderate Risk",
    dmlevel3: "High Risk",
    dmlevel4: "High Risk",
    dmlevel5: "High Risk",
    ccalevel1: "Low Risk",
    ccalevel2: "Moderate Risk",
    ccalevel3: "High Risk",
    suggest: "Suggest for you, ",
    suggestInModal: "Recommendations",

    alwaysSuggest: `Health assessments can be initiated from the age of 20 and above. The health screening program is categorized by age group due to varying risks associated with each age range. Regular health check-ups are essential to identify potential risks and early signs of physical deterioration, and it is recommended to undergo them annually.<br/>`,
    alwaysSuggest2: `For individuals with a direct family history of genetic disease, or those engaging in risky lifestyle behaviors, it is advisable to consider additional disease-specific screening based on these risk factors. <br/>`,
    alwaysSuggest3: `Note: Pre-existing diseases won't be assessed. It is recommended to explore other health programs for those diseases.`,

    nothaverisksuggest: `
    Health check-up depends on age, overall health, congenital disease, family history. and lifestyle of each individual Basic health check-up programs are generally divided into: by gender and age The doctor recommends regular examinations every 1 year. For those with risk factors requiring special surveillance, additional examinations may be required. <br/>
- Age 20 years and over, all genders, have annual health check at least once a year <br/>
*** Note: Age 20 years and over, female, cervical cancer screening. It was found to be number one among Thai women. Regular screening for cervical cancer makes it possible to detect it at an early stage and treat it. can begin to be examined at the beginning of sexual intercourse <br/>
*** If there are symptoms of cancer, diabetes, hypertension and heart disease, no reassessment of risk assessments will be concluded. It is recommended to choose a general health check-up package.
  `,
    moreSuggest: "Additional Recommendations for You",
    ageMoreThan50: `Individuals aged 45 and above are advised to consider colon cancer screening through colonoscopy. This proactive approach helps identify and address potential abnormalities before they progress to an advanced stage.`,
    discount: "Discount",
    notFoundCoupon: "Not Found Coupon",
    notFillDate: "Please select the appointment date",
    notFillDate2: "Please select the 2nd appointment date",
    pleaseSelectHos: "Please select hospital",
    notSelectHospital: "Please select hospital",
    notequalDate:
      "The appointment date and the 2nd appointment date couldn't be the same.",
    thank:
      "Your Package reservation has been successfully completed. Should  there be any changes, our staff will get in touch with you.",
    // register
    thankRegis: "",
    next: "Next",
    ////sex
    sex: "Gender",
    male: "Male",
    female: "Female",
    //// bodydetail
    dateofbirth: "Date of birth",
    date: "Date",
    month: "Month",
    year: "Year",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    signout: "Sign out",
    //// bodydetail
    bodydetail: "Health Details",
    height: "Height",
    weight: "Weight",
    bmi: "BMI",
    waistline: "Waistline",
    centimeter: "cm.",
    kilogram: "kg.",
    inch: "in.",
    bmiunit: "kg/m²",
    //// healthdetail
    healthdetail: "Health Details",
    optional: "(optional)",
    bloodpressure: "Blood Pressure",
    sbp: "Systolic Blood Pressure",
    dbp: "Diastolic Blood Pressure",
    cholesterol: "Cholesterol",
    cholesterolLDL: "Low-density Lipoprotien (LDL)",
    bloodsugar: "Fasting Blood Sugar (FBS)",
    HbA1c: "Hemoglobin A1C (HbA1C)",
    mmhg: "mmHg",
    mgdl: "mg/dL",
    //// behavior
    behavior: "Health Behaviors",
    snoring: "Snoring with tiredness after waking up",
    alcohol: `<div>Drinking more<br/>than 2 glasses of alcohol daily</div>`,
    // healthcheck: "last health check is less than 1 year",
    healthcheck: "Had a health check-up within the past year",
    nothealthcheck: "Never had a health check-up",
    smoking: "Smoking",
    airPollution: "Working in a dusty place or high air pollution",
    morethanone: "Select one or more answers. If none apply, click 'Next'",
    //// disease
    disease: "Health History",
    cancer: "Cancer",
    diabete: "Diabetes",
    hypertension: "Hypertension",
    kidney: "Kidney disease",
    stroke: "Stroke",
    metabolic: "Metabolic syndrome",
    none: "No medical problems",
    other: "Others",
    pleaseFillDiseaseCompletely: "Please fill out the information completely.",
    pleaseFillDisease: "Please Fill.",
    cvd: "Heart Disease",
    morethanoneanswer: "Select one or more answers.",
    morethanonedisease:
      "You can select more than 1 answer. If you don't have these symtoms, you can press next.",
    morethanonediseaseFamily:
      "Select one or more answers. If none apply, click 'Next.'",
    suggestedisease: "Note: Pre-existing diseases won't be assessed.",
    suggestedisease2:
      "*  If there are symptoms of cancer, diabetes, hypertension and heart disease, no reassessment of risk assessments will be concluded. It is recommended to choose a general health check-up package.",
    //// family disease
    famdisease:
      "Direct Family Health History \n(grandfather, grandmother, father, mother, siblings from the same parents)",
    famcancer: "Cancer",
    famdiabete: "Diabetes",
    famhypertension: "Hypertension",
    famcvd: "Heart Disease",
    famgenetic: "Lung cancer",
    //// exercise
    exercise: "Exercise Frequency per Week",
    lessThan3day: "Less than 3 days",
    d3To5: "3-5 days",
    d6To7: "6-7 days",
    dontdo: "I don't exercise",
    select: "Please select your answer.",
    //// welcome
    welcome: "Welcome!",
    Thankyouregister: "Thank You for Your Interest",
    datause: "*Required information to customize an appropriate package.",
    submitData: "Submit",
    contactData: "Contact",
    // email: "",
    registemail: "Email",
    firstname: "First Name*",
    lastname: "Last Name*",
    phoneNumber: "Phone Number*",
    phoneNumberErr: "Phone number is wrong pattern (0xxxxxxxxx)",
    agree: "I agree to Samitivej Hospital Terms of use",
    submit: "Submit",
    sendData: "Analyze Data",
    termTitle: "Term and Condition of Digital Total Health Solution",
    wating: "Please wait a moment...",
    processData:
      "Now we are processing You can either receive the processing results through your email or wait a moment.",
    privacytitle: "Privacy Notice",
    acceptAll: "Accept All",
    yes: "Yes",
    no: "No",
    accept: "Agree",
    privacy: `
    <div style="height:50vh;width:95%;">

    <div data-v-c9d3989a="" data-v-2d634f7c="">
      <div data-aos="" data-index="0" id="1234" template_type_desc="Text"
        class="article-content-block aos-animate aos-init" data-v-467a19dc="" data-v-2d634f7c="" data-v-c9d3989a="">
        <div data-v-467a19dc="">
          <h3 class="article-content-title" data-v-467a19dc=""><strong>Scope of Policy</strong></h3>
          <div class="styled-content-text-block article-content-details" data-v-4351389b="" data-v-467a19dc="">
            <p>Samitivej Hospital (Sukhumvit, Srinakarin, Sriracha, Thonburi, Chonburi, Chinatown)* (“Company”) commits to protect your personal data as a patient who undergoes investigation, treatment, and medical services including other services provided by the Company. Your personal data is to be protected in compliance with the Personal Data Protection Act B.E.2562. The Company as a controller of such personal data is responsible by law for notifying you of this document for reasons and methodology the Company collects, gathers, uses or discloses your personal data, including informing you of your rights as an owner of such personal data.
              &nbsp;</p>
          </div>
        </div>
      </div>
      <div data-aos="" data-index="1" id="1235" template_type_desc="Text"
        class="article-content-block aos-animate aos-init" data-v-467a19dc="" data-v-2d634f7c="" data-v-c9d3989a="">
        <div data-v-467a19dc="">
          <h3 class="article-content-title" data-v-467a19dc=""><strong>Objectives</strong></h3>
          <div class="styled-content-text-block article-content-details" data-v-4351389b="" data-v-467a19dc="">
            <p>The Company processes your personal data under a scope as defined by Personal Data Protection Act B.E. 2562 and processes the data only as necessary for aforementioned action. Then, the Company concludes the use of your personal data as well as explaining the Lawful Basis of Processing for the data as below details as follow:&nbsp;</p>

            <table border="1" cellpadding="1" cellspacing="1" style="width: 100%;">
              <tbody>
                <tr>
                  <td bgcolor="#aac9bc" style="text-align: center; vertical-align: middle;">
                    <strong>Purpose</strong>
                  </td>
                  <td bgcolor="#aac9bc" style="text-align: center; vertical-align: middle;">
                    <strong>Type of Data</strong>
                  </td>
                  <td bgcolor="#aac9bc" style="text-align: center; vertical-align: middle;">
                    <strong>Lawful Basis of Processing</strong>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>
                      1. For the purpose of investigation and providing medical services
                      <br>
                      &nbsp; &nbsp;1.1. Providing medical services within the Company's health providers  </strong><br>
                      the Company's teams of physicians, nurses and/or other staffs in health teams will record your personal data and take such the information for consultation with physicians or medical staffs including taking imaging and video for further follow up and/or any actions according to relevant professional principles throughout the period you are receiving the services as the Company explains detailed information for your understanding prior to starting any services also opens chances for your to ask questions until your satisfaction met.<br>
                    <strong>&nbsp; &nbsp;1.2
                    Providing medical services if necessary to link data among health providers in the network
                    </strong><br>
                    For benefits in providing you medical services, the Company's teams of physicians, nurses and/or other relevant staffs may disclose your personal data to Network Health Providers to provide some types of medical services. The Company establishes measures to protect personal data by agreements among health providers in the network to prevent unlawful processing of your personal data or without authority.
                    <br>
                    <strong>&nbsp; &nbsp;1.3 For Refer between health providers
                    </strong><br>
                    In case, the Company puts or receives a request for refer of a patient from one provider to another for continuation of cares or from one provider to the Company’s provider according to refer as set by the Company. The Company conducts refer according to the Company's defined standards and will use your personal data for the purpose of refer only not for other purposes.
                  </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                  - Identified data <br>
                  - Contact data  <br>
                  - Health data  <br>
                  - Financial data </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                  1. Is necessary to comply with a contract between you and the Company for receiving medical services (Section 24(3)) <br>
                  2. For sensitive personal data: to comply with a legal obligation on disease diagnosis and medical treatment such as Health Facility Act B.E.2541 and Medical Profession Act B.E.2525 (Section 26(5)(a))   <br>
                  3. For sensitive personal data: to protect or suppress threatening to life, body or health, in case the owner of personal data cannot give self-consent such as undergoing Emergency care or refer between health providers (Section 26(1)) </td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>
                  2. For the purpose of analysis study to develop the quality of treatments by unidentified personal data </strong><br>
                  The Company may use your personal data for analysis study to develop the quality of treatments by an overall report with unidentified owners of personal data and the Company will strictly maintains confidentiality of such data</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><span
                      data-contrast="auto" lang="TH-TH" xml:lang="TH-TH">- Statistical data</span><span
                      data-ccp-props="{'201341983':0,'335551550':2,'335551620':2,'335559739':200,'335559740':264,'469777462':[1134],'469777927':[0],'469777928':[1]}">&nbsp;</span>
                  </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                  For the Legitimate Interest of the Company for analyzing statistical data by unidentified personal data to develop and increase the organization's efficiency of treatment and any service provided by the Company (Section 24(5))</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>
                  3. Disclosure of the data to your insurance companies or contractors for purposes of rights to claim compensation from insurance companies or to reimburse medical claims</strong><br>
                  The Company needs to disclose your personal data to insurance companies to comply with a contract that you or the Company makes with the insurance companies for compensation or medical reimbursement. Indeed, the Company will not disclose your personal data to irrelevant parties. </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                  - Identified data<br>
                  - Contact data <br>
                  - Health data  </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                  Once receipt of your intended consent for disclose your personal data: health data to insurance companies for a right to claim compensation from the insurance companies or to reimburse medical claims (Section 26)</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>
                  4. Disclosure of the data to a  party referring you for investigation or a payer when you give consent for disclose personal data </strong><br>
                  In case of agency of either government, private sector or state enterprise refers you to the company for treatments or is a payer for your medical expenses, the Company will disclose your health data which is sensitive personal data to the aforementioned agency only if you give consent to disclose your data to the agency otherwise, the Company will directly send you a result of investigation.</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                  - Identified data <br>
                  - Contact data <br>
                   - Health data  </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                  Once receipt of your intended consent to disclose of your personal data (Section 26)</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>5. For the purpose of linking electronic databases of medical records among health providers via telecommunication network</strong><br>
                  Once you give consent, the Company will enter your personal data into computer system in a format of telecommunication network for your convenience to receive consultation via application and for you to manage your data via the application. To maximize benefits, the system will link electronic database of medical records among Network Health Providers for you to browse your existing personal data maintained in the providers via electronic devices as the Company makes agreement with network  health providers to protect your personal data in compliance with Personal Data Protection Act B.E.2562.</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                  - Identified data <br>
                  - Contact data <br>
                  - Health data  </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                  Once receipt of your intended consent to disclose of your personal data (Section 26)</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>6. For the Company's marketing purpose </strong><br>
                  the Company may collect, gather, use, and process personal data for analyzing your health condition and contacting you for communication, providing medical information and offering promotion, products and services according to your consent. </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                  - Identified data  <br>
                  - Contact data  <br>
                  - Data of subscribing and participating in marketing activities  </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">The Company will perform this activity after receiving your consent to the Company to use your health data for marketing purposes (Section 26)</td>
                </tr>
              </tbody>
            </table>

            <p>Apart from the aforementioned purposes, the Company will not use your personal data for other purposes unless the Personal Data Protection Act B.E.2562 permits such as &nbsp;</p>

            <ul>
              <li>When receipt of your consent (Section 24) or when receipt of intended consent in case of using sensitive personal data (Section 26) &nbsp;
              </li>
              <li>For analysis study or statistic which establishes appropriate protection measures to protect personal data, right and liberty of an owner of personal data (Section 24(1))&nbsp;</li>
              <li>To prevent, suppress threatening to life, body or health (Section 24(2))&nbsp;</li>
              <li>To comply with a contract between the Company and you (Section 24(3))&nbsp;</li>
              <li>To perform duties accordingly to the Company's mission for public interests (Section 24(4))&nbsp;</li>
              <li>Legitimate Interest of the Company or person or other juristic person except the aforementioned interest is less important than basic rights of the personal data owner (Section 24 (5))&nbsp;</li>
              <li>For legal compliance of the Company (Section 24 (6))&nbsp;</li>
              <li>To prevent, suppress threatening to life, body, or health in case the use of sensitive personal data when the owner cannot give self-consent regardless of any causes (Section. 26 (1))&nbsp;</li>
              <li>For establishment rights for legal claims (Section. 26 (4))&nbsp;</li>
              <li>For public health interests or other social protection as the company establishes appropriate measures to protect basic rights and benefits of the personal data owner (Section. 26 (5) (B)) &nbsp;</li>
              <li>For needs to comply with Labor protection law, provision of medical welfare, social security (Section. 26(5) (C))  &nbsp;</li>
            </ul>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" data-index="2" id="1236" template_type_desc="Text"
        class="article-content-block aos-animate aos-init" data-v-467a19dc="" data-v-2d634f7c="" data-v-c9d3989a="">
        <div data-v-467a19dc="">
          <!---->
          <h3 class="article-content-title" data-v-467a19dc="">Scope</h3>
          <div class="styled-content-text-block article-content-details" data-v-4351389b="" data-v-467a19dc="">
            <h3><small>Definition&nbsp;</small></h3>

            <p><strong>“Personal Data” </strong>
            means information related to an individual that can be identifiable either directly or indirectly excluding the information of the decreased particularly&nbsp;</p>

            <p><strong>“Sensitive Personal Data”</strong> means individual data related to race, ethnicity, political opinion, beliefs, religion or philosophy, sexual behavior, criminal records, health information, disability, trade union information, genetic data, biological data (such as facial image data, iris simulation data, fingerprint replica) or any other information that affects the owner of personal data in a similar manner as defined by committee of personal data protection.&nbsp;
            </p>

            <p><strong>“Health Data”</strong> means the following data&nbsp;</p>

            <ul>
              <li>Day, month, year of receiving medical treatment&nbsp;</li>
              <li>History of drug allergy and history of drug side effects&nbsp;</li>
              <li>History of food allergy&nbsp;</li>
              <li>Diagnostic disease, procedure name, surgery name&nbsp;</li>
              <li>Blood result, laboratory result, pathological result, radiological images, and radiological report&nbsp;</li>
              <li>List of prescribed medication&nbsp;</li>
              <li>Other information such as symptoms, physician recommendations, diagnostic details&nbsp;</li>
            </ul>

            <p><strong>“Process”</strong> means collect, gather, use or disclose.&nbsp;</p>

            <p><strong>“Personal Data Controller” </strong>means an individual or juristic person who has authority in decision making about the collection, gathering, use or disclosure of personal data. &nbsp;</p>

            <p><strong>“Personal Data Processor”</strong> means an individual or juristic person who performs collection, gathering, use or disclose of personal data according to orders or on behalf of a personal data controller, in addition, the individual or juristic person performing actions as above must not be a personal data controller.&nbsp;</p>

            <p><strong>“Bangkok Dusit Medical Services Group” </strong>means companies in Bangkok Dusit Medical Services Company Limited (BDMS) network are currently existing or will be in the future, regardless it may be registered in Thailand or overseas, including Bangkok Dusit Medical Services Company Limited.&nbsp;</p>

            <p><strong>“Samitivej Group”</strong> means companies in Samitivej Public Company Limited network are currently existing or will be in the future, regardless it may be registered in Thailand or overseas, including Samitivej Public Company Limited.&nbsp;</p>

            <p><strong>“Network Health Provider” </strong>means health providers in a group or network of Samitivej Public Company Limited and BDMS operating both in Thailand and overseas. &nbsp;</p>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" data-index="3" id="1237" template_type_desc="Text"
        class="article-content-block aos-animate aos-init" data-v-467a19dc="" data-v-2d634f7c="" data-v-c9d3989a="">
        <div data-v-467a19dc="">
          <!---->
          <h3 class="article-content-title" data-v-467a19dc="">Responsibility </h3>
          <div class="styled-content-text-block article-content-details" data-v-4351389b="" data-v-467a19dc="">
            <h3><small>Guideline &nbsp;</small></h3>

            <p><strong>1. Personal Data the Company Collects from You&nbsp;</strong><br>
            Your personal data collected by the Company can be classified as followings &nbsp;</p>

            <table border="1" cellpadding="1" cellspacing="1" style="width: 100%;">
              <tbody>
                <tr>
                  <td bgcolor="#aac9bc" style="text-align: center; vertical-align: middle;" width="50%">
                    <strong>Type of Personal Data </strong>
                  </td>
                  <td bgcolor="#aac9bc" style="text-align: center; vertical-align: middle;" width="50%">
                    <strong>Details</strong>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">1. Personal data</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">Such as name, surname, ID card number, face image, gender, date of birth, passport number or other identifiable numbers. </td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">2. Contact data </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">Such as address, telephone number, e-mail address. </td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">3. Financial data </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">Such as billing information, credit or debit information, receipt information, invoice information. </td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">4. Marketing data </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">Such as registration information used for subscribe and marketing participation.</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">5. Statistical Data</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">Such as unidentified personal data, numbers of patient, and number of websites visiting.</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">6. Technical data </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">Such as IP Address of computer, type of browser, Cookies information time zone setting, operating system, platform and technology of devices used for accessing website and Online Appointment System. </td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">7. Health data </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">Such as treatment information, reports about physical or mental health condition, health cares of service receivers, laboratory test results, diagnosis, diagnostic disease, information about drug use and drug allergy, history of food allergy, blood result, laboratory result, pathological result, radiological images and radiological report, list of prescribed medication, necessary information for medical services, information of feedback and treatments. </td>
                </tr>
              </tbody>
            </table>

            <p>&nbsp;</p>

            <p><strong>2. Sources of Personal Data</strong><br>
            The Company collects and gathers your personal data from the following sources&nbsp;</p>

            <p>1. Personal data directly collected from you such as: &nbsp;</p>

            <p>&nbsp;  1.1 In case, you receive investigation and treatment: The Company receives your personal data from you contacting the Company about services or your self-register with the Company for receiving medical services and other services from the Company, including registration via electronic media. &nbsp;</p>

            <p>&nbsp; 1.2 In case, you are the Company’s vendor: The Company receives your personal data from you contacting the Company about services or from you, as a provider, who makes a contract with the Company. &nbsp;</p>

            <p>2. Personal data indirectly collected from you such as: &nbsp;</p>

            <p>&nbsp; 2.1 Persons who are close to you such as relatives, spouse, etc.&nbsp;</p>

            <p>&nbsp; 2.2 Persons you give authority to act on your behalf in contacting with hospital.&nbsp;</p>

            <p>&nbsp; &nbsp;2.3 Network Health Providers, in case you already give consent to the Network Health Provider for disclosure of your personal data.&nbsp;
            </p>

            <p>&nbsp;2.4 Person, juristic person, or agency of any government, private sector, or state enterprise who refers you for investigation services to the Company or is a payer for your service expenses.</p>

            <p><strong>3. Disclosure or Share of Personal Data&nbsp;</strong><br>
            The Company will not disclose your personal data to outsiders except when laws permit for needs in operation so the Company may disclose your personal data for the following cases. &nbsp;</p>

            <ol>
              <li>Disclose personal data to government agencies, authority agencies or any person when laws define or authorize, including complying with court orders.&nbsp;</li>
              <li>Disclose personal data to individual or juristic persons that the Company needs to comply with the contract or for your benefits as an owner of personal data. The Company requires those individual or juristic persons must maintain confidentiality and protect your personal data according to standards as defined by the Personal Data Protection Act B.E. 2562, including but not limited to individual or juristic persons as listed below.&nbsp;
                <ul>
                  <li>Network Health Providers in Samitivej Group and BDMS as necessary as for providing investigation and medical services to you as the company will disclose personal data only as necessary and the company will maintain the confidentiality of your personal data as its duties complied with relevant laws such as Medical facilities Act B.E. 2541, National Health Act B.E. 2550 and Medical Profession Act B.E. 2525&nbsp;
                  </li>
                  <li>Insurance company or its provider managing compensation.&nbsp;
                  </li>
                  <li>Health provider service receiving patient's refer.&nbsp;</li>
                  <li>The one referring you for investigation or services at a health provider or paying service expenses for you.&nbsp;
                  </li>
                  <li>Personal data processor as necessary for the company’s operation such as employee, or laboratory service provider, database management, telecommunication, computer system, payment or provider of Technology Outsource. (Technology Outsource)&nbsp;</li>
                </ul>
              </li>
              <li>The Company may maintain personal data in Cloud Computing by using such services from the third party located in Thailand or overseas, the Company makes a contract with mentioned persons very thoroughly and considers safety system in maintaining personal data that Cloud Computing service provider functions in regarding personal data protection.&nbsp;</li>
            </ol>

            <p><strong>4. Duration of Personal Data Retention&nbsp;</strong></p>

            <ol>
              <li>The Company uses standards of duration for retention of medical records in accordance with Medical Facilities Act B.E. 2541 and the latest version, the Company will maintain medical records in its system for a minimum of 5 years but not exceed 10 years from your latest medical visit. Once completion of that 10 year duration, all original medical records, copies, and electronic medical records will be disposed.&nbsp;</li>
              <li>In case, the company must comply with laws, regulations of other professional councils, court orders or establish rights for legal claims to enter dispute resolution processes, the Company may maintain such personal data for the duration according to the legal statute or until the dispute is final whichever the case may be.&nbsp;</li>
            </ol>

            <p><strong>5. Measures of Personal Data Retention and Analysis</strong>&nbsp;</p>

            <ol>
              <li>The Company will manage the retention of personal data with standards not less than a level required by law and with appropriate system to protect and safeguard such personal data such as the use of Secure Sockets Layer: SSL, protect with firewall, password and other technology measures for encryption of information via the internet, and store in a facility with access protection system that limits the person’s access to personal data kept in a document format.&nbsp;
              </li>
              <li>The Company limits access to personal data that may be accessed by staffs, agent, partner, or third party. Access to personal data by the third party can be done according to setting or order. Also, the third party is responsible for maintaining confidentiality and personal data protection.&nbsp;</li>
              <li>The Company establishes technology methods to protect unauthorized access to the computer system.&nbsp;
              </li>
              <li>The Company has an inspection system to manage the destruction of unnecessary personal data for the Company.  &nbsp;
              </li>
              <li>In case of sensitive personal data, the Company applies measures to maintain the security of documentation and electronic data for access and control of the use as well as having an operating system and backup including an emergency plan and conducting regularly risk assessment of the system.&nbsp;</li>
            </ol>

            <p><strong>6. Overseas Transfer of Personal Data&nbsp;</strong></p>

            <ol>
              <li>Some cases, the Company may need to transfer your personal data to overseas. The Company may perform the transfer after notifying you of the objectives of the transfer and receiving your consent. Then, the Company may inform you about insufficient standards of personal data protection of the destination country.&nbsp;
              </li>
              <li>The Company can transfer your personal data without your consent if the transfer of personal data to overseas is in accordance with a contract you are as the contract’s partner or to protect or suppress any threatening to the life, body, or health of personal data owner, or for the use according to your request prior to making that contract or according to requirements in Personal Data Protection Act B.E. 2562.</li>
            </ol>

            <p><strong>7. Cookie Policy</strong><br>
            When you visit our website, the Company uses cookie to ensure that you will receive good experience from using the company’s website. Cookie is a small file that stores information and records it on to computer devices or communication tools when you access via web browser you choose while visiting the website.
              &nbsp;</p>

            <p>The Company uses cookie to collect the identity of your website visiting. With such identity, the Company can be easier to remember the nature of your website using and such data will be used for developing the Company’s website to match your needs more. For convenience and speed of your using the website, sometimes The Company may authorize the third party for this operation which may need IP address and cookie for analysis, data link, and processing according to marketing purposes. You can set cookie when you enter the Company’s website as you can choose to allow or not allow cookie to perform analysis, data link and processing according to marketing purposes. &nbsp;</p>

            <p><strong>8. Rights of Personal Data Owner &nbsp;</strong><br>
              As a personal data owner, you have the rights to request the Company to process your personal data according to the scope allowed by laws as below:&nbsp;</p>

            <ol>
              <li>Right to withdraw consent: you have the rights to withdraw your consent for personal data processing as consents to the Company anytime throughout the period your personal data is stored at the Company. &nbsp;</li>
              <li>Right of access: you have rights to access your personal data and request the Company for a copy of the aforementioned personal data, including requesting the company to disclose the acquisition of your personal data you did not give your consent.&nbsp;</li>
              <li>Right to rectification: you have rights to request the Company to correct incorrect data or add to incomplete data.
                &nbsp;</li>
              <li>&nbsp;Right to erasure: you have right to request the Company to erase your data by some reasons.&nbsp;</li>
              <li>Right to restriction of processing: you have rights to request the Company to suppress the use of your personal data by some reasons. &nbsp;</li>
              <li>Right to data portability: you have rights to transfer your personal data maintained by the Company to other data controllers or yourself by some reasons. &nbsp;</li>
              <li>Right to object: you have rights to object your personal data processing by some reasons. </li>
            </ol>

            <p>You can contact our Data Protection Officer: DPO / Data Protection Department Officer to request to exercise your rights as aforementioned at: &nbsp;</p>

            <p>Samitivej Sukhumvit Hospital&nbsp;<br>
            133 Sukhumvit 49, Khlong Tan Nuea, Watthana, Bangkok, 10110&nbsp;<br>
            Tel: 02-022-2222&nbsp;<br>
            E-mail: svh.dpo@samitivej.co.th&nbsp;</p>

            <p>Samitivej Srinakarin Hospital&nbsp;<br>
            488 Srinakarin Rd, Suan Luang, Bangkok, 10250&nbsp;<br>
            Tel: 02-022-2222&nbsp;<br>
            E-mail: svh.dpo@samitivej.co.th&nbsp;</p>

            <p>Samativej Chinatown Hospital&nbsp;<br>
            624 Yaowarat Rd., Samphanthawong, Bangkok, 10100&nbsp;<br>
            Tel: 02-118-7893
            &nbsp;<br>
            E-mail: DPOoffice.sct@samitivej.co.th&nbsp;</p>

            <p>Samitivej Thonburi Hospital&nbsp;<br>
            337, Somdet Phra Chao Tak Sin Rd., Khwaeng Samre, Khet Thon Buri, Bangkok, 10600&nbsp;<br>
            Tel: 02-438-9000&nbsp;<br>
            E-mail: STH-DPO-Group@samitivej.co.th&nbsp;</p>

            <p>Samitivej Chonburi Hospital&nbsp;<br>
            888/88 Moo 3, Sukhumvit Rd., Ban Suan, Mueang Chonburi, Chonburi, 20000&nbsp;<br>
            Tel: 033-038-888&nbsp;<br>
            E-mail: SCH.DPO@samitivej.co.th&nbsp;</p>

            <p>Samitivej Sriracha Hospital&nbsp;<br>
            8 Soi Laemket, Jermompol Rd., Sriracha, Chonburi, 20110&nbsp;<br>
            Tel: 038-320-300, 033-030-100&nbsp;<br>
            E-mail: ssh.dpo@samitivej.co.th&nbsp;</p>

            <p><strong>9. Changes of Personal Data Protection Policy&nbsp;</strong><br>
            The Company may review and change the personal data protection policy in the future for developing better personal data protection, the Company will notify you every time when the aforementioned policy changed.&nbsp;</p>

            <p><strong>10. Contact Channels&nbsp;</strong><br>
            You can contact our data protection officer, inquiry or use any right related to personal data at: &nbsp;</p>

            <p>Samitivej Sukhumvit Hospital&nbsp;<br>
            133 Sukhumvit 49, Khlong Tan Nuea, Watthana, Bangkok, 10110 &nbsp;<br>
            Tel: 02-022-2222&nbsp;<br>
            E-mail: svh.dpo@samitivej.co.th&nbsp;</p>

            <p>Samitivej Srinakarin Hospital&nbsp;<br>
            488 Srinakarin Rd, Suan Luang, Bangkok, 10250&nbsp;<br>
            Tel: 02-022-2222&nbsp;<br>
            E-mail: svh.dpo@samitivej.co.th&nbsp;</p>

            <p>Samitivej Chinatown Hospital&nbsp;<br>
            624 Yaowarat Rd., Samphanthawong, Bangkok, 10100&nbsp;<br>
            Tel: 02-118-7893&nbsp;<br>
            E-mail: DPOoffice.sct@samitivej.co.th&nbsp;</p>

            <p>Samitivej Thonburi Hospital&nbsp;<br>
            337, Somdet Phra Chao Tak Sin Rd., Khwaeng Samre, Khet Thon Buri, Bangkok, 10600&nbsp;<br>
            Tel: 02-438-9000&nbsp;<br>
              E-mail: STH-DPO-Group@samitivej.co.th&nbsp;</p>

            <p>Samitivej Chonburi Hospital&nbsp;<br>
            888/88 Moo 3, Sukhumvit Rd., Ban Suan, Mueang Chonburi, Chonburi, 20000<br>
            Tel: 033-038-888&nbsp;<br>
            E-mail: SCH.DPO@samitivej.co.th&nbsp;</p>

            <p>Samitivej Sriracha Hospital&nbsp;<br>
            8 Soi Laemket, Jermompol Rd., Sriracha, Chonburi, 20110&nbsp;<br>
            Tel : 038-320-300, 033-030-100&nbsp;<br>
            E-mail: ssh.dpo@samitivej.co.th &nbsp;</p>

            <p><strong>Workflow &nbsp;</strong><br>
              N/A
              &nbsp;</p>
            <p><strong>Communication Channel & Training&nbsp;</strong><br>
              N/A
              &nbsp;</p>
            <p><strong>Follow up and Measurement of Procedure and Service&nbsp;</strong><br>
            N/A
              &nbsp;</p>
            <p><strong>Relevant Quality Documents &nbsp;</strong><br>
            N/A
              &nbsp;</p>
            <p><strong>Reference Documents &nbsp;</strong><br>
            N/A
              &nbsp;</p>

            <hr>
            <p>*Samitivej Sukhumvit Hospital which is operated by Samitivej Public Company Limited.  &nbsp;<br>
            Samitivej Srinakarin Hospital which is operated by Samitivej Public Company Limited.  &nbsp;<br>
            Samitivej Sriracha Hospital which is operated by Samitivej Sriracha Company Limited.  &nbsp;<br>
            Samitivej Thonburi Hospital which is operated by Krungdhon Hospital Public Company Limited.  &nbsp;<br>
            Samitivej Chonburi Hospital which is operated by Samitivej Chonburi Company Limited.  &nbsp;<br>
            Samitivej Chinatown Hospital which is operated by Bangkok Dusit Medical Sevice Public Company Limited. &nbsp;</p>
          </div>
        </div>
      </div>
    </div>
  </div>`,
    term: `
    <div style="height:50vh">

    <p>
    This term and condition for using Digital Total Health Solution (“DTHS Service”) is made between SAMITIVEJ PUBLIC COMPANY LIMITED (hereinafter called “Provider”) and the user (hereinafter called, the “User”). The DTHS is a primary risk assessment of the disease e.g. Coronary Artery Disease, Diabetes, and Colorectal cancer by analyzing data from the risk assessment results, age, and gender of the User. In order to use the results of that analysis to provide an annual health examination program by such analysis that matches the physical condition of the User.</p>
  <p>When the User uses the DTHS Service, the User accepts to comply with this term and condition of service as follows: </p>
    <p><strong>1.	Definition</strong></p>
    <p>
    1.1

    “DTHS Service” means Digital Total Health Solution service provided via the provider's website https://www.samitivejhospitals.com/th/package/detail/digital-total-health-solution </br>
    1.2

    “User” means the applicant who uses the service and answers DTHS service's survey via the Provider's website </br>
    1.3

    “Provider” means Samitivej Public Company Limited</br>
    </p>
    <p><strong>2.	DTHS Service</strong></p>
    <p>
    User acknowledges, accepts, and agrees with Provider as following:</br>
    2.1.

    The User must complete a health survey to pre-screen the User's health risk and fill in the personal contact information with respect to informing the risk analysis, presenting a medical examination program that is suitable for the User's physical appearance, and making an appointment for receiving medical examination service. Completing the health survey and filling in the personal contact shall be deemed the User's own action and binding on the User.</br>
    2.2

    The User acknowledges that the medical terminologies concerning diagnosis and medical procedure are words in English and difficult to comprehend by the User's understanding. If the User has a question regarding the diagnosis, the medical treatment, or the medical procedure, please contact the Provider.</br>
    2.3.

    If there are any mistakes regarding the DTHS Service or any questions in relation to health information and others, the User shall notify with the related details to the Provider for using as information for mistake inspection.</br>
    </p>
    <p><strong>3.	Liability and Limitation of the Provider</strong></p>
    <p>
    The Provider shall be liable for the damage caused by the act which is done with intention or gross negligence of the Provider, only for the damage as usually arises. The Provider shall be not liable for any damage as has arisen from special circumstances. Additionally, the User accepts that the Provider shall be not liable for the damage arising from the causes as follows:</br>
    3.1

    Force majeure or any event could not be controlled by the Provider, including the communication device or the connection system is stopped working or disturbed by a computer virus or illegal act or a case where the Provider stops providing the DTHS Service for the purpose of system maintenance which causes the User could not use the DTHS Service.</br>
    3.2

    Delay, mistake, or defect of computer system or any device of the User or Provider or internet service provider or any communication system, excluding a case where such delay, mistake or defect is caused by the intention or gross negligence of the Provider.</br>
    3.3

    Delay of DTHS Service's procedure.</br>
    3.4

    The User does not comply with the term and the condition of DTHS Service.</br>
    </p>
    <p><strong>4.	Contact and Notice</strong></p>
    <p>
    4.1

    The User allows the Provider to make inquiries, collect, gather, or use some or all parts of information in relation to the User and service providing as necessity and appropriateness.</br>
    4.2

    Notice, letter or any notification which the Provider sends by mail or E-mail to the User at the address set out by the User, shall be deemed duly submitted to the User. In case where the User changes the address, the User shall notify in writing to the Provider.</br>
    </p>
    <p><strong>5.	Modification or Termination</strong></p>
    <p>
    The DTHS Service set out in this term are AS IS and AS AVAILABLE services. The Provider reserves the right to change, limit, or terminate the User's access or the use of DTHS Service at any time without prior notice.</p>
    <p>The Provider could modify the term and condition of DTHS Service at any time. If the User continues using the DTHS Service, it shall be deemed that the User acknowledges and accepts to be bound by such modification. The User has the duty to be aware of modification and shall not raise an unawareness of modification of the term and condition of DTHS Service as the cause of term and condition violation.</p>
    <p><strong>6.	Governing Law</strong></p>
    <p>
    For any dispute arising from the DTHS Service provided by the Provider, the User, and the Provider accept that Thai law shall be governed for settling such dispute.
    </p>
    </div>
    `,
    //  noted for thai people
    forThai:
      "* Special price for Thai nationals and foreigners residing in Thailand only.",
    forThai2: "Must schedule an appointment 3 days prior to service",
    // flash sale remark
    flashSaleRemark: `<p>หมายเหตุ : เมื่อทำการจองแพ็กเกจเสร็จสมบูรณ์ ท่านจะได้รับคูปองส่วนลด 5%<br>
    ซึ่งจะถูกแนบมากับอีเมลยืนยันการจองแพ็กเกจ โปรดนำคูปองนี้ไปแสดง <br>
    ที่เคาน์เตอร์ชำระเงิน ณ โรงพยาบาลที่ท่านใช้บริการเพื่อรับส่วนลด 5% <br>
    สอบถามเพิ่มเติม กรุณาติดต่อ Line Official Account : @Wellbysamitivej</p>`,
    flashSaleAds: "12.12 Flash Sale",
    flashSaleAds2: "ลด 5% ทุกแพ็กเกจ",
    recommendedPackages: "Recommnended Packages",
  },
  th: {
    "": "",
    thai: "ไทย",
    english: "English",
    // home
    home: "ค้นหาแพ็กเกจสุขภาพที่เหมาะสมกับคุณ",
    homeDesc: "เพื่อความแม่นยำในการแนะนำ กรุณาตอบคำถามสุขภาพตามความเป็นจริง",
    start: "เริ่มต้น",
    already: "ฉันมีบัญชีอยู่แล้ว",
    agreeUsingData: "ยินยอม ",
    agreeUsingData2:
      "ให้ใช้ข้อมูลเพื่อประมวลผลและนำเสนอแพ็กเกจสุขภาพ และบริการ",
    agreeUsingData3: " ของโรงพยาบาลที่เหมาะสมสำหรับคุณ",
    agreeTermandPrivacy: "ยอมรับ",
    TermAndCond: "เงื่อนไขข้อตกลงในการใช้บริการ",
    and: "และ ",
    privacyPolicy: "นโยบายความเป็นส่วนตัว",
    consent:
      "บริษัท สมิติเวช จำกัด (มหาชน) ขอความยินยอมจากท่าน ในการเก็บ รวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลและข้อมูลใด ๆ ของท่าน เพื่อวัตถุประสงค์ทางการตลาดและการสื่อสาร การโฆษณาทางการตลาด การขาย ข้อเสนอพิเศษ ข่าวสาร ประชาสัมพันธ์ โปรโมชั่นและการนำเสนอผลิตภัณฑ์และบริการต่างๆ ภายใต้ขอบเขตการให้บริการโปรแกรมตรวจสุขภาพเฉพาะบุคคล (Digital Total Health Solution) โดยโรงพยาบาลสมิติเวช",
    close: "ปิด",
    hospital: "โรงพยาบาล",
    pickDate: "วันที่",
    // sigin
    email: "อีเมล",
    password: "รหัสผ่าน",
    confirmpass: "ยืนยันรหัสผ่าน",
    register: "ลงทะเบียน",
    forgot: "ลืมรหัสผ่าน",
    signin: "เข้าสู่ระบบ",
    loading: "โปรดรอสักครู่",
    codeMismatchException: "รหัสยืนยันไม่ถูกต้อง",
    notAuthorizedException: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
    userNotFoundException: "ไม่พบชื่อผู้ใช้",
    userNotConfirmedException: "การลงทะเบียนผู้ใช้ไม่ได้รับการยืนยัน",
    limitExceededException: "เกินขีดจำกัดความพยายาม โปรดลองอีกครั้งในภายหลัง",
    error: "เกิดข้อผิดพลาด!",
    requiredPass: "กรุณากรอกรหัสผ่าน",
    more8TO20Pass: "รหัสผ่านต้องมีความยาว 8-20 ตัวอักษร",
    numberPass: "รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว",
    lowerPass: "รหัสผ่านต้องมีตัวพิมพ์เล็กอย่างน้อย 1 ตัว",
    upperPass: "รหัสผ่านต้องมีตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว",
    SpecialCharPass: 'รหัสผ่านต้องมีอักขระพิเศษอย่างน้อย 1 ตัว (!@#$%^&*")',
    dontMatchPass: "รหัสผ่านไม่ตรงกัน",
    // Forgot
    send: "ส่ง",
    code: "รหัสยืนยัน",
    newPassword: "รหัสผ่านใหม่",
    // ChangePass
    PassSuggest:
      "รหัสผ่านต้องมีความยาว 8-20 ตัวอักษร ประกอบด้วยอักษรภาษาอังกฤษพิมพ์ใหญ่ พิมพ์เล็ก ตัวเลข และอักขระพิเศษ",
    // Booking
    or: " หรือ ",
    recommend: "แพ็กเกจที่แนะนำ",
    selectAll: "เลือกทั้งหมด",
    unSelectAll: "ไม่เลือกทั้งหมด",
    seemore: "ดูเพิ่มเติม",
    seeDetail: "ดูรายละเอียด",
    notIncludeDoctor: "(ราคาดังกล่าวไม่รวมค่าแพทย์)",
    hideDetail: "ซ่อนรายละเอียด",
    hello: "สวัสดี",
    riskscore: "ความเสี่ยง",
    pleasSelect: "กรุณาเลือกแพ็กเกจ ",
    pleasSelect2: " ก่อนเลือกแพ็กเกจนี้",
    pleaseUnselect: "มีรายการตรวจซ้ำกับแพ็กเกจอื่น",
    pleaseUnselect2: "กรุณาเลือกแพ็กเกจนี้เพียงแพ็กเกจเดียว",
    selectHospitalHeader: "กรุณาเลือกโรงพยาบาล เพื่อดูโปรแกรมสุขภาพที่แนะนำ",
    choosehospital: "เลือกโรงพยาบาล",
    choosehospital2: "สำรองวันนัดตรวจสุขภาพ",
    sukhumvit: "สมิติเวช สุขุมวิท",
    srinakarin: "สมิติเวช ศรีนครินทร์",
    sriracha: "สมิติเวช ศรีราชา",
    thonburi: "สมิติเวช ธนบุรี",
    chonburi: "สมิติเวช ชลบุรี",
    chinatown: "สมิติเวช ไชน่าทาวน์",
    appdate: "วันที่",
    summary: "สรุปโปรแกรมตรวจสุขภาพ:",
    coupon: "โค้ดส่วนลด",
    subtotal: "โปรแกรมที่เลือก",
    showSamePackage:
      "* เนื่องจากเป็นแพ็กเกจที่ซ้ำกัน เป็นเพียงการแสดงรายละเอียดให้ลูกค้าทราบ ระบบไม่คิดค่าใช้จ่ายซ้ำ",
    total: "รวมเป็นเงิน",
    checkout: "จอง",
    pay: "ชำระเงิน",
    paymentMethod: "ช่องทางการชำระเงิน",
    qrCode: "QR Code",
    creditCard: "Credit/Debit",
    yourrisk: "ระดับความเสี่ยง",
    cvdlevel1: "ความเสี่ยงน้อย",
    cvdlevel2: "ความเสี่ยงปานกลาง",
    cvdlevel3: "ความเสี่ยงสูง",
    cvdlevel4: "ความเสี่ยงสูงมาก",
    cvdlevel5: "ความเสี่ยงสูงอันตราย",
    dmlevel1: "ความเสี่ยงน้อย",
    dmlevel2: "ความเสี่ยงปานกลาง",
    dmlevel3: "ความเสี่ยงสูง",
    dmlevel4: "ความเสี่ยงสูงมาก",
    dmlevel5: "ความเสี่ยงสูงอันตราย",
    ccalevel1: "ความเสี่ยงน้อย",
    ccalevel2: "ความเสี่ยงปานกลาง",
    ccalevel3: "ความเสี่ยงสูง",
    suggest: "คำแนะนำสำหรับคุณ",
    suggestInModal: "คำแนะนำสำหรับคุณ",
    alwaysSuggest: `การตรวจสุขภาพสามารถเริ่มตรวจได้ตั้งแต่อายุ 20 ปี ขึ้นไป โปรแกรมตรวจสุขภาพจะแบ่งตามกลุ่มอายุ เพราะในแต่ละช่วงวัยมีความเสี่ยงต่างกัน การตรวจสุขภาพเพื่อค้นหาความเสี่ยงและความเสื่อมของร่างกายเบื้องต้นเป็นประจำทุกปีจึงมีความจำเป็นและสำคัญ <br/>`,
    alwaysSuggest2: `สำหรับผู้ที่มีประวัติโรคพันธุกรรมในครอบครัวสายตรง หรือมีพฤติกรรมการใช้ชีวิตที่มีความเสี่ยง ควรพิจารณาตรวจคัดกรองโรคจากปัจจัยเสี่ยงเหล่านี้เพิ่มเติมด้วย <br/>`,
    alwaysSuggest3: `หมายเหตุ: ระบบจะไม่ประเมินความเสี่ยง โรคที่ตรวจพบหรือเป็นอยู่ก่อนหน้านี้แล้ว แนะนำพิจารณาเลือกโปรแกรมสุขภาพอื่นๆ`,
    nothaverisksuggest: `
    การตรวจสุขภาพขึ้นอยู่กับอายุ สุขภาพโดยรวม โรคประจำตัว ประวัติครอบครัว และรูปแบบการใช้ชีวิตของแต่ละบุคคล โดยทั่วไปแล้วโปรแกรมตรวจสุขภาพเบื้องต้นจะแบ่ง ตามเพศและอายุ โดยแพทย์แนะนำให้ตรวจเป็นประจำทุก 1 ปี  สำหรับผู้ที่มีปัจจัยเสี่ยงต้องเฝ้าระวังเป็นพิเศษอาจจำเป็นต้องมีการตรวจเพิ่มเติม <br/>
  `,
    nothaverisksuggest2: `- อายุ 20 ปีขึ้นไป ทุกเพศ ตรวจสุขภาพประจำปีอย่างน้อยปีละครั้ง   <br/>
  *** หมายเหตุ : อายุ 20 ปีขึ้นไป เพศหญิง ตรวจมะเร็งปากมดลูก พบมากเป็นอันดับหนึ่งของผู้หญิงไทย การตรวจคัดกรองมะเร็งปากมดลูก (Cervical Cancer) อย่างสม่ำเสมอทำให้มีโอกาสตรวจพบได้ตั้งแต่ระยะเริ่มแรกและรักษาได้ สามารถเริ่มตรวจได้เมื่อเริ่มมีเพศสัมพันธ์`,
    moreSuggest: "คำแนะนำเพิ่มเติมสำหรับคุณ",
    ageMoreThan50: `ในผู้ที่มีอายุ 45 ปีขึ้นไป ควรตรวจคัดกรองโรคมะเร็งลำไส้ใหญ่โดยการส่องกล้องเพื่อป้องกันและหาความผิดปกติก่อนเกิดระยะลุกลาม`,
    discount: "ส่วนลด",
    notFoundCoupon: "ไม่พบโค้ดส่วนลด",
    notFillDate: "กรุณาเลือกวันที่นัดหมาย",
    notFillDate2: "กรุณาเลือกวันที่นัดหมาย (สำรอง)",
    pleaseSelectHos: "กรุณาเลือกโรงพยาบาล",
    notSelectHospital: "กรุณาเลือกโรงพยาบาล",
    notequalDate: "วันที่นัดหมาย และวันที่นัดหมายสำรองต้องไม่ใช่วันเดียวกัน",
    thank:
      "การจองแพ็กเกจของท่านสำเร็จแล้ว<br>หากมีการเปลี่ยนแปลงเจ้าหน้าที่จะติดต่อกลับ",
    signout: "ออกจากระบบ",
    // register
    thankRegis: "",
    next: "ถัดไป",
    ////sex
    sex: "เพศของคุณ",
    male: "ชาย",
    female: "หญิง",
    //// bodydetail
    dateofbirth: "วัน เดือน ปีเกิด",
    date: "วันที่",
    month: "เดือน",
    year: "ปี",
    january: "มกราคม",
    february: "กุมภาพันธ์",
    march: "มีนาคม",
    april: "เมษายน",
    may: "พฤษภาคม",
    june: "มิถุนายน",
    july: "กรกฎาคม",
    august: "สิงหาคม",
    september: "กันยายน",
    october: "ตุลาคม",
    november: "พฤศจิกายน",
    december: "ธันวาคม",
    //// bodydetail
    bodydetail: "ข้อมูลสุขภาพ",
    height: "ส่วนสูง",
    weight: "น้ำหนัก",
    bmi: "BMI",
    waistline: "รอบเอว",
    centimeter: "ซม.",
    kilogram: "กก.",
    inch: "นิ้ว",
    bmiunit: "กก./ม.²",
    //// healthdetail
    healthdetail: "ข้อมูลสุขภาพ",
    optional: "(ถ้ามี)",
    bloodpressure: "ความดันโลหิต",
    sbp: "ความดันตัวบน",
    dbp: "ความดันตัวล่าง",
    cholesterol: "คอเลสเตอรอล",
    cholesterolLDL: "ระดับไขมัน LDL",
    bloodsugar: "น้ำตาลในเลือด (FBS)",
    HbA1c: "น้ำตาลสะสมในเลือด (HbA1C)",
    mmhg: "mmHg",
    mgdl: "mg/dL",
    //// behavior
    behavior: "พฤติกรรมสุขภาพ",
    snoring: "นอนกรนและเหนื่อยเพลียหลังตื่นนอน",
    alcohol: `<div>ดื่มแอลกอฮอล์<br/>เกินวันละ 2 แก้ว<br/>เป็นประจำ</div>`,
    healthcheck: "ตรวจสุขภาพภายใน 1 ปี",
    nothealthcheck: "ไม่เคยตรวจสุขภาพ",
    smoking: "สูบบุหรี่",
    airPollution: "ทำงานในสถานที่ที่มีฝุ่นควัน หรือมลภาวะทางอากาศ",
    morethanone:
      "ตอบได้มากกว่า 1 ข้อ หรือ หากไม่มีพฤติกรรมเหล่านี้ กรุณากดถัดไป",
    //// disease
    disease: "ประวัติสุขภาพ",
    cancer: "โรคมะเร็ง",
    diabete: "โรคเบาหวาน",
    hypertension: "โรคความดันโลหิตสูง",
    kidney: "โรคไต",
    stroke: "โรคหลอดเลือดสมอง",
    metabolic: "ภาวะอ้วนลงพุง",
    none: "ไม่มีโรคประจำตัว",
    other: "อื่นๆ",
    pleaseFillDiseaseCompletely: "โปรดกรอกข้อมูลให้ครบถ้วน",
    pleaseFillDisease: "โปรดระบุ",
    cvd: "โรคหัวใจ",
    morethanoneanswer: "ตอบได้มากกว่า 1 ข้อ",
    morethanonedisease:
      "พฤติกรรมสุขภาพตอบได้มากกว่า 1 ข้อ หรือ หากไม่มีพฤติกรรมเหล่านี้ กรุณากดถัดไป",
    morethanonediseaseFamily: "ตอบได้มากกว่า 1 ข้อ หรือ หากไม่มี กรุณากดถัดไป",
    suggestedisease:
      "* ระบบจะไม่ประเมินความเสี่ยงโรคที่ตรวจพบ หรือเป็นอยู่ก่อนหน้านี้แล้ว ",
    suggestedisease2:
      "* หากมีอาการโรคมะเร็ง , โรคเบาหวาน , โรคความดันโลหิตสูง , โรคหัวใจ และโรคหลอดเลือดสมอง จะไม่มีสรุปการประเมินความเสี่ยงโรคซ้ำ แนะนำให้ลูกค้าเลือกแพ็กเกจตรวจสุขภาพทั่วไป",
    //// family disease
    famdisease:
      "ประวัติสุขภาพครอบครัวสายตรง \n(ปู่ ย่า ตา ยาย พ่อ แม่ พี่น้องท้องเดียวกัน)",
    famcancer: "โรคมะเร็ง",
    famdiabete: "โรคเบาหวาน",
    famhypertension: "โรคความดันโลหิตสูง",
    famcvd: "โรคหัวใจ",
    famgenetic: "โรคมะเร็งปอด",
    //// exercise
    exercise: "การออกกำลังกายในหนึ่งสัปดาห์",
    lessThan3day: "น้อยกว่า 3 วัน",
    d3To5: "3-5 วัน",
    d6To7: "6-7 วัน",
    dontdo: "ฉันไม่ออกกำลังกาย",
    select: "กรุณาเลือกคำตอบ",
    //// welcome
    welcome: "ยินดีต้อนรับ",
    Thankyouregister: "ขอบคุณที่ท่านให้ความสนใจ",
    datause: "* ข้อมูลใช้เพื่อนำเสนอ package ที่เหมาะสม",
    submitData: "ส่งข้อมูล",
    contactData: "ข้อมูลสำหรับการติดต่อ",
    // email: "",
    registemail: "อีเมล",
    firstname: "ชื่อ*",
    lastname: "นามสกุล*",
    phoneNumber: "เบอร์โทรศัพท์*",
    phoneNumberErr: "เบอร์โทรศัพท์ไม่ถูกต้อง (0xxxxxxxxx)",
    agree:
      "ฉันยอมรับ ข้อกำหนดและเงื่อนไข และ นโยบายความเป็นส่วนตัว ของโรงพยาบาลสมิติเวช",
    submit: "ส่ง",
    sendData: "วิเคราะห์ข้อมูล",
    termTitle:
      "ข้อกำหนดและเงื่อนไขการใช้บริการโปรแกรมตรวจสุขภาพเฉพาะบุคคล (Digital Total Health Solution)",
    wating: "กรุณารอสักครู่...",
    processData:
      "ตอนนี้เรากำลังประมวลผล ท่านสามารถรับผลการประมวลผ่านอีเมลของท่านหรือรอสักครู่",
    privacytitle: "ประกาศการคุ้มครองข้อมูลส่วนบุคคล (Privacy Notice)",
    acceptAll: "ยอมรับทั้งหมด",
    yes: "ยอมรับ",
    no: "ไม่ยอมรับ",
    accept: "ยอมรับ",
    privacy: `
    <div style="height:50vh;width:95%;">

    <div data-v-c9d3989a="" data-v-2d634f7c="">
      <div data-aos="" data-index="0" id="1234" template_type_desc="Text"
        class="article-content-block aos-animate aos-init" data-v-467a19dc="" data-v-2d634f7c="" data-v-c9d3989a="">
        <div data-v-467a19dc="">
          <h3 class="article-content-title" data-v-467a19dc=""><strong>กรอบนโยบาย</strong></h3>
          <div class="styled-content-text-block article-content-details" data-v-4351389b="" data-v-467a19dc="">
            <p>โรงพยาบาลสมิติเวช (สุขุมวิท , ศรีนครินทร์ , ศรีราชา , ธนบุรี , ชลบุรี , ไชน่าทาวน์)* (“บริษัทฯ”)
              มุ่งมั่นที่จะคุ้มครองข้อมูลส่วนบุคคลของท่านในฐานะที่ท่านเป็นผู้เข้ารับบริการตรวจ รักษาโรค
              และบริการทางการแพทย์ รวมถึงบริการต่างๆ จากบริษัทฯ
              ข้อมูลส่วนบุคคลของท่านจะได้รับการคุ้มครองตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
              บริษัทฯ
              ในฐานะผู้ควบคุมข้อมูลส่วนบุคคลมีหน้าที่ตามกฎหมายในการแจ้งเอกสารฉบับนี้ให้ท่านทราบถึงเหตุผลและวิธีการที่บริษัทฯ
              เก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
              รวมถึงแจ้งให้ท่านทราบสิทธิของท่านในฐานะเจ้าของข้อมูลส่วนบุคคล
              &nbsp;</p>
          </div>
        </div>
      </div>
      <div data-aos="" data-index="1" id="1235" template_type_desc="Text"
        class="article-content-block aos-animate aos-init" data-v-467a19dc="" data-v-2d634f7c="" data-v-c9d3989a="">
        <div data-v-467a19dc="">
          <h3 class="article-content-title" data-v-467a19dc=""><strong>วัตถุประสงค์</strong></h3>
          <div class="styled-content-text-block article-content-details" data-v-4351389b="" data-v-467a19dc="">
            <p>บริษัทฯ
              ประมวลผลข้อมูลส่วนบุคคลของท่านภายใต้ขอบเขตที่กำหนดไว้โดยพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
              พ.ศ. 2562 และประมวลผลข้อมูลเพียงเท่าที่จำเป็นสำหรับการดำเนินการดังกล่าว โดยบริษัทฯ
              ได้สรุปการใช้ข้อมูลส่วนบุคคลของท่าน พร้อมทั้งอธิบายฐานการประมวลผลข้อมูลส่วนบุคคลที่ชอบด้วยกฎหมาย
              (Lawful Basis of Processing) ดังนี้&nbsp;</p>

            <table border="1" cellpadding="1" cellspacing="1" style="width: 100%;">
              <tbody>
                <tr>
                  <td bgcolor="#aac9bc" style="text-align: center; vertical-align: middle;">
                    <strong>วัตถุประสงค์ </strong>
                  </td>
                  <td bgcolor="#aac9bc" style="text-align: center; vertical-align: middle;">
                    <strong>ประเภทข้อมูล </strong>
                  </td>
                  <td bgcolor="#aac9bc" style="text-align: center; vertical-align: middle;">
                    <strong>ฐานการประมวลผลที่ชอบด้วยกฎหมาย </strong>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>
                      1. เพื่อวัตถุประสงค์ในการตรวจรักษาโรคและให้บริการทางการแพทย์<br>
                      &nbsp; &nbsp;1.1. การให้บริการทางการแพทย์ภายในสถานพยาบาลของบริษัทฯ </strong><br>
                    คณะแพทย์ พยาบาล และ/หรือ บุคลากรอื่นๆ ในทีมสุขภาพของบริษัทฯ
                    จะทำการบันทึกข้อมูลส่วนบุคคลของท่าน
                    นำข้อมูลส่วนบุคคลของท่านเพื่อปรึกษาแพทย์หรือบุคลากรทางการแพทย์
                    รวมถึงถ่ายภาพนิ่งและภาพเคลื่อนไหว เพื่อติดตามการรักษา และ/หรือ กระทำการใดๆ
                    ตามหลักวิชาชีพที่เกี่ยวข้องตลอดระยะเวลาที่ท่านเข้ารับบริการ โดยบริษัทฯ
                    จะอธิบายข้อมูลรายละเอียดให้ท่านได้เข้าใจก่อนที่จะดำเนินการ
                    อีกทั้งเปิดโอกาสให้ท่านซักถามจนเป็นที่พอใจ<br>
                    <strong>&nbsp; &nbsp;1.2
                      การให้บริการทางการแพทย์ในกรณีจำเป็นต้องเชื่อมโยงข้อมูลระหว่างสถานพยาบาลในเครือข่าย
                    </strong><br>
                    เพื่อประโยชน์ในการให้บริการทางการแพทย์แก่ท่าน คณะแพทย์ พยาบาล และ/หรือ
                    บุคลากรอื่นๆเกี่ยวข้องของบริษัทฯ
                    อาจเปิดเผยข้อมูลส่วนบุคคลของท่านให้สถานพยาบาลในเครือข่ายในกรณีที่จำเป็นต้องใช้ข้อมูลระหว่างสถานพยาบาลในเครือข่ายเพื่อการให้บริการบางประเภท
                    ทั้งนี้ บริษัทฯ
                    ได้จัดให้มีมาตรการคุ้มครองข้อมูลส่วนบุคคลโดยมีข้อตกลงร่วมกันระหว่างสถานพยาบาลในเครือข่ายเพื่อป้องกันไม่ให้เกิดการประมวลผลข้อมูลส่วนบุคคลของท่านในทางที่มิชอบด้วยกฎหมาย
                    หรือโดยปราศจากอำนาจ<br>
                    <strong>&nbsp; &nbsp;1.3 เพื่อรับ-ส่งต่อผู้ป่วยระหว่างสถานพยาบาล (Refer)
                    </strong><br>
                    ในกรณีที่บริษัทฯ
                    มีคำร้องขอหรือได้รับการร้องขอให้เคลื่อนย้ายผู้ป่วยจากสถานพยาบาลหนึ่ง
                    เพื่อไปรับการตรวจรักษาต่อที่สถานพยาบาลอื่น
                    หรือมีคำร้องขอหรือได้รับคำร้องขอให้รับผู้ป่วยจากสถานพยาบาลหนึ่งเพื่อเข้ารับการรักษาในสถานพยาบาลของบริษัทฯ
                    ตามกระบวนการรับ-ส่งต่อผู้ป่วยระหว่างสถานพยาบาล (Refer) บริษัทฯ
                    จะต้องดำเนินตามกระบวนการรับ-ส่งต่อผู้ป่วยที่กำหนดไว้ตามมาตรฐานของบริษัทฯ
                    และจะใช้ข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ในการรับ-ส่งต่อผู้ป่วยเท่านั้น
                    ไม่นำไปใช้ในวัตถุประสงค์อื่น
                  </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                    ข้อมูลระบุตัวตน<br>
                    ข้อมูลสำหรับการติดต่อ<br>
                    ข้อมูลด้านสุขภาพ<br>
                    ข้อมูลการเงิน</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">1.
                    เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญาเข้ารับการรักษาพยาบาลที่ท่านเป็นคู่สัญญากับบริษัทฯ
                    (ม.24 (3))<br>
                    2. สำหรับข้อมูลส่วนบุคคลที่อ่อนไหว:
                    ฐานปฏิบัติตามกฎหมายในการวินิจฉัยโรคและการรักษาทางการแพทย์ เช่น
                    พระราชบัญญัติสถานพยาบาล พ.ศ. 2541 และ พระราชบัญญัติวิชาชีพเวชกรรม พ.ศ. 2525 (ม.26
                    (5) (ก))<br>
                    3. สสำหรับข้อมูลส่วนบุคคลที่อ่อนไหว: เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย
                    หรือสุขภาพในกรณีที่เจ้าของข้อมูลส่วนบุคคลไม่สามารถให้ความยินยอมได้ เช่น
                    การเข้ารับบริการในกรณีฉุกเฉิน (Emergency Care) หรือเพื่อรับ -
                    ส่งต่อผู้ป่วยระหว่างโรงพยาบาล (Refer) (ม.26 (1))</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>2.
                      เพื่อวัตถุประสงค์ในการศึกษาวิเคราะห์เพื่อพัฒนาคุณภาพของการรักษาพยาบาลโดยไม่บ่งชี้ตัวตนของเจ้าของข้อมูลส่วนบุคคล</strong><br>
                    บริษัทฯ
                    อาจนำข้อมูลส่วนบุคคลของท่านไปใช้เพื่อการศึกษาวิเคราะห์เพื่อการพัฒนาคุณภาพของการรักษาพยาบาล
                    โดยจะทำเป็นรูปแบบรายงานผลโดยภาพรวมที่ไม่มีการบ่งชี้ตัวตนของเจ้าของข้อมูล และบริษัทฯ
                    จะรักษาความลับของข้อมูลดังกล่าวของท่านอย่างเคร่งครัด</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><span
                      data-contrast="auto" lang="TH-TH" xml:lang="TH-TH">ข้อมูลสถิติ</span><span
                      data-ccp-props="{'201341983':0,'335551550':2,'335551620':2,'335559739':200,'335559740':264,'469777462':[1134],'469777927':[0],'469777928':[1]}">&nbsp;</span>
                  </td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                    เพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัท (Legitimate Interest)
                    ในการวิเคราะห์ข้อมูลสถิติโดยไม่ใช้ข้อมูลส่วนบุคคลที่ระบุตัวตนได้เพื่อพัฒนาและเพิ่มประสิทธิภาพขององค์กรในด้านการรักษาพยาบาล
                    และการให้บริการต่างๆ ของบริษัทฯ (ม.24 (5))</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>3.
                      การเปิดเผยข้อมูลให้บริษัทประกันภัยที่ท่านหรือบริษัทฯ
                      เป็นคู่สัญญาเพื่อวัตถุประสงค์ในการใช้สิทธิเรียกค่าสินไหมทดแทนจากบริษัทประกันภัยหรือใช้สิทธิเบิกค่ารักษาพยาบาล</strong><br>
                    บริษัทจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของท่านให้บริษัทประกันภัยเพื่อการปฏิบัติตามสัญญาที่ท่านหรือบริษัทฯ
                    ได้ทำไว้กับบริษัทประกันภัย
                    เพื่อประโยชน์ในการเบิกจ่ายค่าสินไหมทดแทนหรือใช้สิทธิเบิกค่ารักษาพยาบาล ทั้งนี้
                    บริษัทฯ จะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านให้กับบุคคลอื่นใดที่ไม่เกี่ยวข้อง</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                    ข้อมูลระบุตัวตน<br>
                    ข้อมูลสำหรับการติดต่อ<br>
                    ข้อมูลด้านสุขภาพ</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                    เมื่อได้รับความยินยอมโดยชัดแจ้งจากท่านในการเปิดเผยข้อมูลส่วนบุคคลที่เป็นข้อมูลด้านสุขภาพให้บริษัทประกันภัยเพื่อประโยชน์ในการใช้สิทธิเรียกค่าสินไหมทดแทนจากบริษัทประกันภัยหรือใช้สิทธิเบิกค่ารักษาพยาบาล
                    (ม.26)</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>4.
                      การเปิดเผยข้อมูลให้ผู้ที่ส่งท่านมาตรวจหรือเป็นผู้ชำระเงินเมื่อท่านได้ยินยอมให้เปิดเผยข้อมูลส่วนบุคคล</strong><br>
                    ในกรณีที่หน่วยงานไม่ว่าภาครัฐ เอกชน
                    หรือรัฐวิสาหกิจเป็นผู้ส่งท่านมาตรวจรักษากับบริษัทฯ
                    หรือเป็นผู้ชำระค่าบริการตรวจรักษาให้แก่ท่าน บริษัทฯ
                    จะเปิดเผยข้อมูลการตรวจรักษาซึ่งเป็นข้อมูลส่วนบุคคลที่อ่อนไหวให้กับบุคคลดังกล่าวเฉพาะกรณีที่ท่านได้ให้ความยินยอมในการเปิดเผยข้อมูลส่วนบุคคลของท่านให้กับบุคคลดังกล่าวเท่านั้น
                    ถ้าท่านไม่ได้ให้ความยินยอมดังกล่าว บริษัทฯ จะส่งผลตรวจรักษาให้ท่านโดยตรง</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                    ข้อมูลระบุตัวตน<br>
                    ข้อมูลสำหรับการติดต่อ<br>
                    ข้อมูลด้านสุขภาพ</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                    เมื่อได้รับความยินยอมโดยชัดแจ้งจากท่านในการเปิดเผยข้อมูลส่วนบุคคล (ม.26)</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>5.
                      เพื่อวัตถุประสงค์ในการเชื่อมโยงฐานข้อมูลอิเล็กทรอนิกส์ด้านเวชระเบียนระหว่างสถานพยาบาลผ่านระบบเครือข่ายสารสนเทศ</strong><br>
                    เมื่อท่านให้ความยินยอม บริษัทฯ
                    จะนำข้อมูลส่วนบุคคลของท่านเข้าสู่ระบบคอมพิวเตอร์ในรูปแบบเครือข่ายสารสนเทศเพื่ออำนวยความสะดวกให้ท่านในการรับคำปรึกษาผ่านแอพพลิเคชั่น
                    และเพื่อให้ท่านสามารถบริหารจัดการข้อมูลผ่านแอพพลิเคชั่นได้
                    และเพื่อให้เกิดประโยชน์สูงสุด
                    ระบบจะทำการเชื่อมโยงฐานข้อมูลอิเล็กทรอนิกส์ด้านเวชระเบียนระหว่างสถานพยาบาลในเครือข่ายเพื่อให้ท่านสามารถเรียกดูข้อมูลส่วนบุคคลของท่านที่มีอยู่กับสถานพยาบาลในเครือข่ายผ่านอุปกรณ์อิเล็กทรอนิกส์ต่างๆ
                    ได้ โดยบริษัทฯ
                    มีความตกลงร่วมกับสถานพยาบาลในเครือข่ายเพื่อคุ้มครองข้อมูลส่วนบุคคลของท่านให้เป็นไปตามที่
                    พรบ.คุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 กำหนด</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                    ข้อมูลระบุตัวตน<br>
                    ข้อมูลสำหรับการติดต่อ<br>
                    ข้อมูลด้านสุขภาพ</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                    เมื่อได้รับความยินยอมโดยชัดแจ้งจากท่านในการเปิดเผยข้อมูลด้านสุขภาพระหว่างสถานพยาบาล
                    (ม.26)</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top"><strong>6.
                      เพื่อวัตถุประสงค์ทางการตลาดของบริษัทฯ</strong><br>
                    บริษัทฯ อาจทำการเก็บรวบรวม ใช้
                    และประมวลผลข้อมูลส่วนบุคคลเพื่อวิเคราะห์สภาวะสุขภาพของท่าน และติดต่อเพื่อสื่อสาร
                    ส่งข้อมูลข่าวสารด้านการแพทย์ และนำเสนอโปรโมชั่น สินค้าและบริการ
                    แก่ท่านตามที่ท่านได้ให้ความยินยอม</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">
                    ข้อมูลระบุตัวตน<br>
                    ข้อมูลสำหรับการติดต่อ<br>
                    ข้อมูลการสมัครข่าวสารและการเข้าร่วมกิจกรรมทางการตลาด</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">บริษัทฯ
                    จะดำเนินการในเรื่องนี้ได้หลังจากได้รับความยินยอมจากท่านในการให้บริษัทฯ
                    นำข้อมูลด้านสุขภาพไปใช้เพื่อวัตถุประสงค์ทางการตลาด (ม.26)</td>
                </tr>
              </tbody>
            </table>

            <p>นอกจากวัตถุประสงค์ที่ระบุข้างต้นแล้ว บริษัทฯ
              จะไม่นำข้อมูลส่วนบุคคลของท่านไปใช้เพื่อวัตถุประสงค์อื่น
              ยกเว้นในกรณีที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 อนุญาต เช่น &nbsp;</p>

            <ul>
              <li>เมื่อได้รับความยินยอมจากท่าน &nbsp;(ม.24)
                หรือเมื่อได้รับความยินยอมโดยชัดแจ้งจากท่านในกรณีการใช้ข้อมูลส่วนบุคคลที่อ่อนไหว (ม.26)&nbsp;
              </li>
              <li>เพื่อการศึกษาวิจัยหรือสถิติซึ่งได้จัดให้มีมาตรการปกป้องที่เหมาะสมเพื่อคุ้มครองข้อมูลส่วนบุคคลสิทธิและเสรีภาพของเจ้าของข้อมูลส่วนบุคคล
                (ม.24 (1))&nbsp;</li>
              <li>เพื่อป้องกันระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพ (ม.24 (2))&nbsp;</li>
              <li>เพื่อการปฏิบัติตามสัญญาระหว่างบริษัทฯ กับท่าน (ม.24 (3))&nbsp;</li>
              <li>เพื่อปฏิบัติหน้าที่ในการดำเนินภารกิจเพื่อประโยชน์สาธารณะของบริษัทฯ (ม.24 (4))&nbsp;</li>
              <li>เพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัท (Legitimate Interest) หรือของบุคคลหรือนิติบุคคลอื่น
                เว้นแต่ประโยชน์ดังกล่าวมีความสำคัญน้อยกว่าสิทธิขั้นพื้นฐานของเจ้าของข้อมูลส่วนบุคคล (ม.24
                (5))&nbsp;</li>
              <li>เพื่อการปฏิบัติตามกฎหมายของบริษัทฯ (ม.24 (6))&nbsp;</li>
              <li>เพื่อป้องกันระงับอันตรายต่อชีวิต ร่างกาย
                หรือสุขภาพในกรณีที่การใช้ข้อมูลส่วนบุคคลที่อ่อนไหวที่เจ้าของข้อมูลส่วนบุคคลไม่สามารถให้ความยินยอมได้
                ไม่ว่ากรณีใดก็ตาม (ม.26 (1)) &nbsp;</li>
              <li>เพื่อการก่อตั้งสิทธิเรียกร้องตามกฎหมาย (ม.26 (4))&nbsp;</li>
              <li>เพื่อประโยชน์ด้านสาธารณสุข หรือการคุ้มครองทางสังคมอื่นใดโดยบริษัทฯ
                จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิขั้นพื้นฐานและประโยชน์ของเจ้าของข้อมูลส่วนบุคคล
                (ม.26 (5) (ข))&nbsp;</li>
              <li>เพื่อความจำเป็นในการปฏิบัติตามกฎหมายด้านการคุ้มครองแรงงาน การให้สวัสดิการรักษาพยาบาล
                การประกันสังคม (ม.26 (5) (ค)))&nbsp;</li>
            </ul>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" data-index="2" id="1236" template_type_desc="Text"
        class="article-content-block aos-animate aos-init" data-v-467a19dc="" data-v-2d634f7c="" data-v-c9d3989a="">
        <div data-v-467a19dc="">
          <!---->
          <h3 class="article-content-title" data-v-467a19dc="">ขอบเขต</h3>
          <div class="styled-content-text-block article-content-details" data-v-4351389b="" data-v-467a19dc="">
            <h3><small>นิยาม&nbsp;</small></h3>

            <p><strong>“ข้อมูลส่วนบุคคล” </strong>หมายถึง
              ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม
              แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ&nbsp;</p>

            <p><strong>“ข้อมูลส่วนบุคคลที่อ่อนไหว”</strong> หมายถึง ข้อมูลส่วนบุคคลที่เกี่ยวกับเชื้อชาติ
              เผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ
              ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ (เช่น
              ข้อมูลภาพจำลองใบหน้า ข้อมูลจำลองม่านตา ข้อมูลจำลองลายนิ้วมือ)
              หรือข้อมูลอื่นใดที่กระทบต่อเจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลประกาศกำหนด&nbsp;
            </p>

            <p><strong>“ข้อมูลการรักษาพยาบาล”</strong> หมายถึง ข้อมูลดังต่อไปนี้&nbsp;</p>

            <ul>
              <li>วันเดือนปีที่เข้ารับการรักษา &nbsp;</li>
              <li>ประวัติแพ้ยาและประวัติผลข้างเคียงจากยา &nbsp;</li>
              <li>ประวัติแพ้อาหาร&nbsp;</li>
              <li>ชื่อโรคที่ได้รับการวินิจฉัย ชื่อหัตถการ และชื่อการผ่าตัด&nbsp;</li>
              <li>ผลเลือด ผลตรวจทางห้องปฏิบัติการ ผลตรวจชิ้นเนื้อทางพยาธิวิทยา ภาพถ่ายทางรังสีวิทยา
                และรายงานผลการ ทางรังสีวิทยา&nbsp;</li>
              <li>รายการยาที่แพทย์ได้สั่ง&nbsp;</li>
              <li>ข้อมูลอื่น เช่น อาการ คำแนะนำของแพทย์ และรายละเอียดการวินิจฉัยโรค เป็นต้น&nbsp;</li>
            </ul>

            <p><strong>“ประมวลผล”</strong> หมายถึง เก็บรวบรวม ใช้ หรือเปิดเผย&nbsp;</p>

            <p><strong>“ผู้ควบคุมข้อมูลส่วนบุคคล” </strong>หมายถึง
              บุคคลหรือนิติบุคคลที่มีอํานาจในการตัดสินใจเกี่ยวกับการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคล&nbsp;</p>

            <p><strong>“ผู้ประมวลผลข้อมูลส่วนบุคคล”</strong> หมายถึง
              บุคคลหรือนิติบุคคลที่มีอํานาจในการตัดสินใจเกี่ยวกับการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล
              “ผู้ประมวลผลข้อมูลส่วนบุคคล” หมายถึง บุคคลหรือนิติบุคคลซึ่งดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของผู้ควบคุมข้อมูลส่วนบุคคล ทั้งนี้
              บุคคลหรือนิติบุคคลซึ่งดำเนินการดังกล่าวไม่เป็นผู้ควบคุมข้อมูลส่วนบุคคล&nbsp;</p>

            <p><strong>“กลุ่มบริษัทกรุงเทพดุสิตเวชการ” </strong>หมายถึง บริษัทที่อยู่ในเครือข่ายของบริษัท
              กรุงเทพดุสิตเวชการ จำกัด (มหาชน)
              ทั้งที่มีอยู่ในปัจจุบันและที่จะมีขึ้นในอนาคตไม่ว่าจะจดทะเบียนในประเทศไทยหรือในต่างประเทศ
              ซึ่งรวมถึงบริษัท กรุงเทพดุสิตเวชการ จำกัด (มหาชน) ด้วย&nbsp;</p>

            <p><strong>“กลุ่มบริษัทสมิติเวช”</strong> หมายถึง บริษัทที่อยู่ในเครือข่ายของบริษัท สมิติเวช จำกัด
              (มหาชน) ทั้งที่มีอยู่ในปัจจุบันและที่จะมีขึ้นในอนาคตไม่ว่าจะจดทะเบียนในประเทศไทยหรือในต่างประเทศ
              ซึ่งรวมถึงบริษัท สมิติเวช จำกัด (มหาชน) ด้วย&nbsp;</p>

            <p><strong>“สถานพยาบาลในเครือข่าย” </strong>หมายถึง สถานพยาบาลในกลุ่มหรือในเครือข่ายของบริษัท
              สมิติเวช จำกัด (มหาชน)และ บริษัท กรุงเทพดุสิตเวชการ จำกัด (มหาชน)
              ทั้งที่ประกอบกิจการในประเทศไทยและในต่างประเทศ &nbsp;</p>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" data-index="3" id="1237" template_type_desc="Text"
        class="article-content-block aos-animate aos-init" data-v-467a19dc="" data-v-2d634f7c="" data-v-c9d3989a="">
        <div data-v-467a19dc="">
          <!---->
          <h3 class="article-content-title" data-v-467a19dc="">หน้าที่ความรับผิดชอบ</h3>
          <div class="styled-content-text-block article-content-details" data-v-4351389b="" data-v-467a19dc="">
            <h3><small>แนวทางปฏิบัติ &nbsp;</small></h3>

            <p><strong>1. ข้อมูลส่วนบุคคลที่บริษัทฯ เก็บรวบรวมจากท่าน&nbsp;</strong><br>
              ข้อมูลส่วนบุคคลของท่านที่บริษัทฯ เก็บรวบรวม สามารถจำแนกเป็นประเภทดังต่อไปนี้&nbsp;</p>

            <table border="1" cellpadding="1" cellspacing="1" style="width: 100%;">
              <tbody>
                <tr>
                  <td bgcolor="#aac9bc" style="text-align: center; vertical-align: middle;" width="50%">
                    <strong>ประเภทข้อมูลส่วนบุคคล</strong>
                  </td>
                  <td bgcolor="#aac9bc" style="text-align: center; vertical-align: middle;" width="50%">
                    <strong>รายละเอียด</strong>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">1.
                    ข้อมูลระบุตัวตน (Personal data)</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">เช่น ชื่อ
                    นามสกุล เลขที่บัตรประชาชน ID รูปถ่ายใบหน้า เพศ วันเดือนปีเกิด หนังสือเดินทาง
                    หรือหมายเลขที่ระบุตัวตนอื่น ๆ</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">2.
                    ข้อมูลสำหรับการติดต่อ (Contact data)</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">เช่น
                    ที่อยู่ หมายเลขโทรศัพท์ อีเมล</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">3.
                    ข้อมูลการเงิน (Financial data)</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">เช่น
                    ข้อมูลการเรียกเก็บเงิน ข้อมูลบัตรเครดิตหรือเดบิต ข้อมูลใบเสร็จ ข้อมูลใบราคา</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">4.
                    ข้อมูลการสมัครข่าวสารและการเข้าร่วมกิจกรรมทางการตลาด (Marketing Data)</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">เช่น
                    ข้อมูลที่ใช้ในการลงทะเบียนเพื่อรับข่าวสารและเข้าร่วมกิจกรรมทางการตลาด</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">5.
                    ข้อมูลสถิติ (Statistical Data)</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">เช่น
                    ข้อมูลที่ไม่ระบุตัวตน จำนวนผู้ป่วย และจำนวนการเข้าชมเว็บไซต์</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">6.
                    ข้อมูลจากการเข้าใช้เว็บไซต์ (Technical data)</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">เช่น
                    หมายเลข IP Address ของคอมพิวเตอร์ ชนิดของบราวเซอร์ ข้อมูล Cookies
                    การตั้งค่าเรื่องเขตเวลา (time zone) ระบบปฏิบัติการ
                    แพลตฟอร์มและเทคโนโลยีของอุปกรณ์ที่ใช้เข้าเว็บไซต์ และ Online Appointment System</td>
                </tr>
                <tr>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">7.
                    ข้อมูลด้านสุขภาพ (Health data)</td>
                  <td align="left" style="text-align: left; vertical-align: top;" valign="top">เช่น
                    ข้อมูลการรักษาพยาบาล รายงานที่เกี่ยวกับสุขภาพกายและสุขภาพจิต
                    การดูแลสุขภาพของผู้รับบริการ ผลการทดสอบจากห้องทดลอง การวินิจฉัย
                    ชื่อโรคที่ได้รับการวินิจฉัย ข้อมูลที่เกี่ยวข้องกับการใช้ยาและแพ้ยา ประวัติแพ้อาหาร
                    ผลเลือด ผลตรวจทางห้องปฏิบัติการ ผลตรวจชิ้นเนื้อทางพยาธิวิทยา ภาพถ่ายทางรังสีวิทยา
                    และรายงานผลการตรวจทางรังสีวิทยารายการยาที่แพทย์ได้สั่ง
                    ข้อมูลที่จำเป็นต่อการให้บริการทางการแพทย์ ข้อมูล Feedback และผลการรักษา</td>
                </tr>
              </tbody>
            </table>

            <p>&nbsp;</p>

            <p><strong>2. แหล่งที่มาของข้อมูลส่วนบุคคล</strong><br>
              บริษัทฯ เก็บรวบรวมข้อมูลส่วนบุคคลของท่านจากแหล่งที่มาดังต่อไปนี้&nbsp;</p>

            <p>1.&nbsp; ข้อมูลส่วนบุคคลที่ได้โดยตรงจากท่าน ได้แก่ &nbsp;</p>

            <p>&nbsp; 1.1 กรณีที่ท่านเป็นผู้เข้ารับบริการตรวจและรักษาโรค: บริษัทฯ
              ได้ข้อมูลส่วนบุคคลของท่านจากการที่ท่านติดต่อสอบถามกับบริษัทฯ เรื่องการบริการ
              หรือท่านได้ลงทะเบียนเข้ารับบริการทางการแพทย์ และบริการต่างๆ จากบริษัทฯ ด้วยตนเองที่บริษัทฯ
              รวมทั้งการลงทะเบียนผ่านสื่ออิเล็กทรอนิกส์ &nbsp;</p>

            <p>&nbsp; 1.2 กรณีที่ท่านเป็นผู้ให้บริการ (Vendor) ของบริษัทฯ: บริษัทฯ
              ได้ข้อมูลส่วนบุคคลของท่านจากการที่ท่านติดต่อสอบถามกับบริษัทฯ เพื่อเข้ามาให้บริการกับบริษัทฯ
              หรือการที่บริษัทฯ
              เก็บรวบรวมข้อมูลส่วนบุคคลของท่านในฐานะที่ท่านเป็นผู้ให้บริการที่เข้าทำสัญญากับบริษัทฯ &nbsp;</p>

            <p>2. ข้อมูลส่วนบุคคลที่ได้รับโดยทางอ้อม ได้แก่ &nbsp;</p>

            <p>&nbsp; 2.1 บุคคลที่มีความใกล้ชิดกับท่าน เช่น ญาติ คู่สมรส เป็นต้น&nbsp;</p>

            <p>&nbsp; 2.2 บุคคลที่ท่านมอบอำนาจให้ดำเนินการแทนตัวท่านในการติดต่อกับโรงพยาบาล&nbsp;</p>

            <p>&nbsp; &nbsp;2.3
              สถานพยาบาลในเครือข่ายในกรณีที่ท่านได้ให้ความยินยอมกับสถานพยาบาลในเครือข่ายไว้ว่าให้เปิดเผยข้อมูลส่วนบุคคลของท่านได้&nbsp;
            </p>

            <p>&nbsp; 2.4 บุคคล นิติบุคคล หรือหน่วยงานไม่ว่าภาครัฐ เอกชน
              หรือรัฐวิสาหกิจที่เป็นผู้ส่งท่านมาตรวจรักษาหรือใช้บริการกับบริษัทฯ
              หรือเป็นผู้ชำระค่าบริการให้กับท่าน</p>

            <p><strong>3. การเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคล&nbsp;</strong><br>
              บริษัทฯ จะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านให้บุคคลภายนอก
              ยกเว้นเป็นกรณีที่กฎหมายอนุญาตเพื่อความจำเป็นในการปฏิบัติงาน ซึ่งทำให้บริษัทฯ
              อาจเปิดเผยข้อมูลส่วนบุคคลได้ในกรณีต่อไปนี้ &nbsp;</p>

            <ol>
              <li>เปิดเผยข้อมูลส่วนบุคคลให้หน่วยงานราชการ หน่วยงานผู้มีอำนาจหรือบุคคลใดๆ
                เมื่อมีกฎหมายกำหนดหรือให้อำนาจ รวมถึงการปฏิบัติตามคำสั่งศาล&nbsp;</li>
              <li>การเปิดเผยข้อมูลส่วนบุคคลให้กับบุคคลหรือนิติบุคคลที่บริษัทฯ
                จำเป็นต้องปฏิบัติตามสัญญาหรือเพื่อผลประโยชน์ของท่านในฐานะเจ้าของข้อมูลส่วนบุคคล โดยบริษัทฯ
                กำหนดให้บุคคลหรือนิติบุคคลเหล่านี้ต้องรักษาความลับและคุ้มครองข้อมูลส่วนบุคคลของท่านตามมาตรฐานที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                พ.ศ. 2562 กำหนด ซึ่งรวมถึงแต่ไม่จำกัดเพียงบุคคลหรือนิติบุคคลในข้อนี้ได้แก่&nbsp;
                <ul>
                  <li>สถานพยาบาลในเครือข่ายกลุ่มบริษัทสมิติเวชและกลุ่มบริษัทกรุงเทพดุสิตเวชการ
                    เท่าที่จำเป็นในการให้บริการการตรวจรักษาโรคและให้บริการทางการแพทย์กับท่าน โดยบริษัทฯ
                    จะเปิดเผยข้อมูลส่วนบุคคลเฉพาะส่วนที่จำเป็นเท่านั้น และบริษัทฯ
                    จะรักษาความลับให้กับข้อมูลส่วนบุคคลของท่านตามหน้าที่ที่บริษัทฯ
                    มีภายใต้กฎหมายที่เกี่ยวข้อง เช่น พระราชบัญญัติสถานพยาบาล พ.ศ. 2541
                    พระราชบัญญัติสุขภาพแห่งชาติ พ.ศ. 2550 และพระราชบัญญัติวิชาชีพเวชกรรม พ.ศ. 2525&nbsp;
                  </li>
                  <li>บริษัทประกันภัย หรือผู้ให้บริการบริหารจัดการสินไหมทดแทนของบริษัทประกันภัยนั้น&nbsp;
                  </li>
                  <li>สถานพยาบาลที่รับการส่งต่อผู้ป่วย &nbsp;</li>
                  <li>ผู้ส่งท่านมาตรวจรักษาหรือใช้บริการกับสถานพยาบาล หรือชำระเงินค่าบริการแทนท่าน&nbsp;
                  </li>
                  <li>ผู้ประมวลผลข้อมูลส่วนบุคคลที่จำเป็นต่อการปฏิบัติงานของบริษัทฯ เช่น ผู้รับจ้าง
                    หรือผู้ให้บริการตรวจทางห้องปฏิบัติการ การจัดทำข้อมูล การโทรคมนาคม ระบบคอมพิวเตอร์
                    การชำระเงิน หรือการให้บริการด้านเทคโนโลยี (Technology Outsource) &nbsp;</li>
                </ul>
              </li>
              <li>บริษัทฯ อาจเก็บข้อมูลส่วนบุคคลไว้ในระบบประมวลผลแบบคลาวด์ (Cloud Computing)
                โดยใช้บริการจากบุคคลที่สามไม่ว่าตั้งอยู่ในประเทศไทยหรือต่างประเทศ โดยบริษัทฯ
                ได้เข้าทำสัญญากับบุคคลดังกล่าวด้วยความระมัดระวังและพิจารณาถึงระบบรักษาความปลอดภัยในการเก็บรักษาข้อมูลส่วนบุคคลที่ผู้ให้บริการระบบ
                Cloud Computing นั้นให้กับการคุ้มครองข้อมูลส่วนบุคคล&nbsp;</li>
            </ol>

            <p><strong>4. ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล&nbsp;</strong></p>

            <ol>
              <li>บริษัทฯ ใช้มาตรฐานระยะเวลาการเก็บเวชระเบียนตามพระราชบัญญัติสถานพยาบาล พ.ศ. 2541
                และฉบับแก้ไขล่าสุด โดยบริษัทฯ จะเก็บเวชระเบียนขั้นต่ำ5ปีแต่ไม่เกิน 10
                ปีนับจากวันที่มารับการรับการรักษาพยาบาลครั้งสุดท้าย เมื่อครบกำหนด 10
                ปีแล้วจะทำลายทิ้งทั้งเวชระเบียนฉบับจริง สำเนา และเวชระเบียนรูปแบบอิเล็กทรอนิกส์ &nbsp;</li>
              <li>ในกรณีที่บริษัทฯ ต้องปฏิบัติตามกฎหมาย ปฏิบัติตามคำสั่งศาล
                หรือต้องก่อตั้งสิทธิเรียกร้องตามกฎหมายเพื่อเข้ากระบวนการระงับข้อพิพาทใดๆ บริษัทฯ
                อาจจัดเก็บข้อมูลส่วนบุคคลได้ตามระยะเวลาของอายุความตามกฎหมาย
                หรือจนกว่าข้อพิพาทนั้นจะถึงที่สุดแล้วแต่กรณี&nbsp;</li>
            </ol>

            <p><strong>5. มาตรการในการเก็บรักษาและประมวลผลข้อมูลส่วนบุคคล</strong>&nbsp;</p>

            <ol>
              <li>บริษัทฯ จะจัดการเก็บรักษาข้อมูลส่วนบุคคลด้วยมาตรการไม่น้อยกว่าระดับที่กฎหมายกำหนด
                และด้วยระบบที่เหมาะสม เพื่อป้องกันและรักษาความปลอดภัยข้อมูลส่วนบุคคลนั้น เช่น
                ใช้โปรโตคอลดความปลอดภัย (Secure Sockets Layer: SSL) ปกป้องด้วยไฟร์วอลล์ รหัสผ่าน
                และมาตรการทางเทคนิคอื่นๆ สำหรับการเข้ารหัสข้อมูลผ่านทางอินเตอร์เน็ต
                และจัดเก็บในสถานที่ที่มีระบบป้องกันการเข้าถึงที่จำกัดบุคคลที่สามารถเข้าถึงได้สำหรับข้อมูลส่วนบุคคลที่อยู่ในรูปแบบเอกสาร&nbsp;
              </li>
              <li>บริษัทฯ จำกัดการเข้าถึงข้อมูลส่วนบุคคลที่อาจจะเข้าถึงได้โดยพนักงาน ตัวแทน คู่ค้า
                หรือบุคคลภายนอก
                การเข้าถึงข้อมูลส่วนบุคคลของบุคคลภายนอกจะสามารถทำได้ตามเท่าที่กำหนดไว้หรือตามคำสั่ง
                ซึ่งบุคคลภายนอกจะต้องมีหน้าที่ในการรักษาความลับและคุ้มครองข้อมูลส่วนบุคคล&nbsp;</li>
              <li>บริษัทฯ
                จัดให้มีวิธีการทางเทคโนโลยีเพื่อป้องกันไม่ให้มีการเข้าสู่ระบบคอมพิวเตอร์ที่ไม่ได้รับอนุญาต&nbsp;
              </li>
              <li>บริษัทฯ
                มีระบบตรวจสอบเพื่อจัดการทำลายข้อมูลส่วนบุคคลที่ไม่มีความจำเป็นในการดำเนินการของบริษัทฯ&nbsp;
              </li>
              <li>ในกรณีที่เป็นข้อมูลส่วนบุคคลที่อ่อนไหวบริษัทฯ
                จะจัดให้มีมาตรการการรักษาความมั่นคงปลอดภัยของข้อมูลเอกสารและข้อมูลอิเล็กทรอนิกส์ในด้านการเข้าถึงและควบคุมการใช้งาน
                มีระบบการใช้งานและระบบสำรองพร้อมทั้งแผนสำหรับกรณีฉุกเฉิน
                และมีการตรวจสอบประเมินความเสี่ยงของระบบอย่างสม่ำเสมอ&nbsp;</li>
            </ol>

            <p><strong>6. การโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ&nbsp;</strong></p>

            <ol>
              <li>ในบางกรณี บริษัทฯ อาจมีความจำเป็นต้องโอนข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ บริษัทฯ
                อาจดำเนินการดังกล่าวได้หลังจากที่ได้แจ้งกับท่านถึงวัตถุประสงค์ของการดำเนินการดังกล่าว
                และได้รับการยินยอมจากท่านแล้ว โดยบริษัทฯ
                จะแจ้งให้ท่านทราบถึงมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่อาจไม่เพียงพอของประเทศปลายทาง&nbsp;
              </li>
              <li>บริษัทฯ
                สามารถโอนข้อมูลส่วนบุคคลของท่านไปได้โดยไม่ต้องขอความยินยอมจากท่านในกรณีที่การโอนข้อมูลส่วนบุคคลไปต่างประเทศนั้นเป็นไปเพื่อปฏิบัติตามสัญญาซึ่งท่านเป็นคู่สัญญา
                หรือเพื่อใช้ในการดำเนินการตามคำขอของท่านก่อนเข้าทำสัญญานั้น
                หรือเป็นไปตามข้อกำหนดในพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562</li>
            </ol>

            <p><strong>7. นโยบายเกี่ยวกับคุกกี้ Cookie Policy</strong><br>
              เมื่อท่านเยี่ยมชมเว็บไซต์ของบริษัทฯ บริษัทฯ
              ใช้คุกกี้เพื่อให้มั่นใจว่าท่านจะได้รับประสบการณ์ที่ดีจากการใช้งานบนเว็บไซต์ของบริษัทฯ
              คุกกี้เป็นไฟล์ขนาดเล็กที่จัดเก็บข้อมูลและบันทึกลงไปในอุปกรณ์คอมพิวเตอร์หรือเครื่องมือสื่อสารที่เข้าใช้งานของท่านผ่านทางเว็บเบราว์เซอร์ที่ท่านเลือกใช้ในขณะที่ท่านเข้าชมเว็บไซต์
              &nbsp;</p>

            <p>บริษัทฯ ใช้คุกกี้เพื่อเก็บเอกลักษณ์การเข้าเยี่ยมชมเว็บไซต์ของท่านโดยเอกลักษณ์นี้ทำให้บริษัทฯ
              สามารถจำลักษณะการใช้งานเว็บไซต์ของท่านได้ง่ายขึ้น
              และข้อมูลเหล่านี้จะถูกนำไปใช้เพื่อปรับปรุงเว็บไซต์ของบริษัทฯ
              ให้เข้ากับความต้องการของท่านมากยิ่งขึ้น เพื่อความสะดวกและรวดเร็วในการใช้งานเว็บไซต์ท่าน
              ในบางครั้ง บริษัทฯ
              จำเป็นต้องให้บุคคลที่สามในการดำเนินการดังกล่าวซึ่งอาจต้องใช้อินเทอร์เน็ตโปรโตคอลแอดเดรส (IP
              Address) และคุกกี้เพื่อวิเคราะห์ เชื่อมโยงข้อมูล และประมวลผลตามวัตถุประสงค์ทางการตลาด
              ท่านสามารถตั้งค่าคุกกี้ได้เมื่อท่านเข้าสู่เว็บไซต์ของบริษัทฯ
              โดยท่านสามารถเลือกได้ว่าจะอนุญาตหรือไม่อนุญาตให้คุกกี้ทำการวิเคราะห์ เชื่อมโยงข้อมูล
              และประมวลผลตามวัตถุประสงค์ทางการตลาด&nbsp;</p>

            <p><strong>8. สิทธิของเจ้าของข้อมูลส่วนบุคคล&nbsp;</strong><br>
              ในฐานะเจ้าของข้อมูลส่วนบุคคล ท่านมีสิทธิร้องขอให้บริษัทฯ
              ดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลของท่านตามขอบเขตที่กฎหมายอนุญาตให้กระทำได้ ดังนี้&nbsp;</p>

            <ol>
              <li>สิทธิในการเพิกถอนความยินยอม (right to withdraw consent): ท่านมีสิทธิในการเพิกถอนความ
                ยินยอมในการประมวลผลข้อมูลสวนบุคคลที่ท่านได้ให้ความยินยอมกับบริษัทฯ ได้ตลอดระยะเวลาที่
                ข้อมูลส่วนบุคคลของท่านอยู่กับบริษัทฯ&nbsp;</li>
              <li>สิทธิในการเข้าถึงข้อมูลส่วนบุคคล (right of access):
                ท่านมีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลของ ท่านและขอให้บริษัทฯ ทำสำเนาข้อมูลส่วนบุคคลดังกล่าว
                รวมถึงขอให้บริษัทฯ เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ท่านไม่ได้ให้ความยินยอมต่อบริษัทฯ
                ให้แก่ท่านได้ &nbsp;</li>
              <li>สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (right to rectification):
                ท่านมีสิทธิในการขอให้บริษัทฯ แก้ไขข้อมูลที่ไม่ถูกต้อง หรือ เพิ่มเติมข้อมูลที่ไม่สมบูรณ์
                &nbsp;</li>
              <li>&nbsp;สิทธิในการลบข้อมูลส่วนบุคคล (right to erasure): ท่านมีสิทธิในการขอให้บริษัทฯ
                ทำการลบข้อมูลของ ท่านด้วยเหตุบางประการได้ &nbsp;</li>
              <li>สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล (right to restriction of processing):
                ท่านมีสิทธิในการให้บริษัทฯ ระงับการใช้ข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้ &nbsp;</li>
              <li>สิทธิในการให้โอนย้ายข้อมูลส่วนบุคคล (right to data portability): ท่านมีสิทธิในการโอนย้าย
                ข้อมูลส่วนบุคคลของท่านที่ท่านให้ไว้กับบริษัทฯ ไปยังผู้ควบคุมข้อมูลรายอื่น
                หรือตัวท่านเองด้วยเหตุบาง ประการได้ &nbsp;</li>
              <li>สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล (right to object): ท่านมีสิทธิในการคัดค้านการ
                ประมวลผลข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้ &nbsp;</li>
            </ol>

            <p>ท่านสามารถติดต่อมายังเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer: DPO) /
              เจ้าหน้าที่ฝ่ายคุ้มครองข้อมูลส่วนบุคคล ของเราได้ เพื่อดำเนินการ
              ยื่นคำร้องขอดำเนินการตามสิทธิข้างต้น ได้ที่ &nbsp;</p>

            <p>โรงพยาบาลสมิติเวช สุขุมวิท&nbsp;<br>
              133 สุขุมวิท 49 แขวงคลองตันเหนือ เขตวัฒนา กรุงเทพฯ 10110&nbsp;<br>
              เบอร์โทรศัพท์ : 02-022-2222&nbsp;<br>
              อีเมล์: svh.dpo@samitivej.co.th&nbsp;</p>

            <p>โรงพยาบาลสมิติเวช ศรีนครินทร์&nbsp;<br>
              488 ถนนศรีนครินทร์ แขวงสวนหลวง เขตสวนหลวง กรุงเทพฯ 10250&nbsp;<br>
              เบอร์โทรศัพท์ : 02-022-2222&nbsp;<br>
              อีเมล์: svh.dpo@samitivej.co.th&nbsp;<br>

            <p>โรงพยาบาลสมิติเวช ไชน่าทาวน์&nbsp;<br>
              624 ถนนเยาวราช แขวงสัมพันธวงศ์ เขตสัมพันธวงศ์ กรุงเทพมหานคร 10100&nbsp;<br>
              เบอร์โทรศัพท์ : 02-118-7893&nbsp;<br>
              อีเมล์: DPOoffice.sct@samitivej.co.th&nbsp;</p>

            <p>โรงพยาบาลสมิติเวช ธนบุรี&nbsp;<br>
              337 ถนน สมเด็จพระเจ้าตากสิน แขวง สำเหร่ เขตธนบุรี กรุงเทพมหานคร 10600&nbsp;<br>
              เบอร์โทรศัพท์ : 02-438-9000&nbsp;<br>
              อีเมล์: STH-DPO-Group@samitivej.co.th&nbsp;</p>

            <p>โรงพยาบาลสมิติเวช ชลบุรี&nbsp;<br>
              888/88 หมู่ 3 ถนนสุขุมวิท ต.บ้านสวน อ.เมืองชลบุรี จ.ชลบุรี 20000&nbsp;<br>
              เบอร์โทรศัพท์ : 033-038-888&nbsp;<br>
              อีเมล์: SCH.DPO@samitivej.co.th&nbsp;</p>

            <p>โรงพยาบาลสมิติเวช ศรีราชา&nbsp;<br>
              8 ซอย แหลมเกตุ ถนน เจิมจอมพล ตำบล ศรีราชา อำเภอ ศรีราชา จังหวัด ชลบุรี 20110&nbsp;<br>
              เบอร์โทรศัพท์ : 038-320-300, 033-030-100&nbsp;<br>
              อีเมล์: ssh.dpo@samitivej.co.th&nbsp;</p>

            <p><strong>9. การเปลี่ยนแปลงนโยบายคุ้มครองข้อมูลส่วนบุคคล&nbsp;</strong><br>
              บริษัทฯ
              อาจทบทวนและเปลี่ยนแปลงนโยบายการคุ้มครองข้อมูลส่วนบุคคลในอนาคตเพื่อพัฒนาให้เกิดการคุ้มครองข้อมูลส่วนบุคคลที่ดีขึ้น
              โดยบริษัทฯ จะแจ้งให้ท่านทราบทุกครั้งที่มีการเปลี่ยนแปลงนโยบายดังกล่าว&nbsp;</p>

            <p><strong>10. ช่องทางการติดต่อ&nbsp;</strong><br>
              ท่านสามารถติดต่อผู้ควบคุมข้อมูล สอบถามหรือใช้สิทธิใดๆ ที่เกี่ยวข้องกับข้อมูลส่วนบุคคล ได้ที่
              &nbsp;</p>

            <p>โรงพยาบาลสมิติเวช สุขุมวิท&nbsp;<br>
              133 สุขุมวิท 49 แขวงคลองตันเหนือ เขตวัฒนา กรุงเทพฯ 10110&nbsp;<br>
              เบอร์โทรศัพท์ : 02-022-2222&nbsp;<br>
              อีเมล์: svh.dpo@samitivej.co.th&nbsp;</p>

            <p>โรงพยาบาลสมิติเวช ศรีนครินทร์&nbsp;<br>
            488 ถนนศรีนครินทร์ แขวงสวนหลวง เขตสวนหลวง กรุงเทพฯ 10250&nbsp;<br>
            เบอร์โทรศัพท์ : 02-022-2222&nbsp;<br>
            อีเมล์: svh.dpo@samitivej.co.th&nbsp;<br>

            <p>โรงพยาบาลสมิติเวช ไชน่าทาวน์&nbsp;<br>
              624 ถนนเยาวราช แขวงสัมพันธวงศ์ เขตสัมพันธวงศ์ กรุงเทพมหานคร 10100&nbsp;<br>
              เบอร์โทรศัพท์ : 02-118-7893&nbsp;<br>
              อีเมล์: DPOoffice.sct@samitivej.co.th&nbsp;</p>

            <p>โรงพยาบาลสมิติเวช ธนบุรี&nbsp;<br>
              337 ถนน สมเด็จพระเจ้าตากสิน แขวง สำเหร่ เขตธนบุรี กรุงเทพมหานคร 10600&nbsp;<br>
              เบอร์โทรศัพท์ : 02-438-9000&nbsp;<br>
              อีเมล์: STH-DPO-Group@samitivej.co.th&nbsp;</p>

            <p>โรงพยาบาลสมิติเวช ชลบุรี&nbsp;<br>
              888/88 หมู่ 3 ถนนสุขุมวิท ต.บ้านสวน อ.เมืองชลบุรี จ.ชลบุรี 20000&nbsp;<br>
              เบอร์โทรศัพท์ : 033-038-888&nbsp;<br>
              อีเมล์: SCH.DPO@samitivej.co.th&nbsp;</p>

            <p>โรงพยาบาลสมิติเวช ศรีราชา&nbsp;<br>
              8 ซอย แหลมเกตุ ถนน เจิมจอมพล ตำบล ศรีราชา อำเภอ ศรีราชา จังหวัด ชลบุรี 20110&nbsp;<br>
              เบอร์โทรศัพท์ : 038-320-300, 033-030-100&nbsp;<br>
              อีเมล์: ssh.dpo@samitivej.co.th&nbsp;</p>

            <p><strong>Workflow &nbsp;</strong><br>
              ไม่มี
              &nbsp;</p>
            <p><strong>ช่องทางการสื่อสาร และการอบรม (Communication Channel & Training)&nbsp;</strong><br>
              ไม่มี
              &nbsp;</p>
            <p><strong>การเฝ้าติดตามและการวัดกระบวนการ/การบริการ&nbsp;</strong><br>
              ไม่มี
              &nbsp;</p>
            <p><strong>เอกสารคุณภาพที่เกี่ยวข้อง&nbsp;</strong><br>
              ไม่มี
              &nbsp;</p>
            <p><strong>เอกสารอ้างอิง&nbsp;</strong><br>
              ไม่มี
              &nbsp;</p>

            <hr>
            <p>*โรงพยาบาลสมิติเวช สุขุมวิท ซึ่งประกอบกิจการโดย บริษัท สมิติเวช จำกัด (มหาชน)&nbsp;<br>
            โรงพยาบาลสมิติเวช ศรีนครินทร์ ซึ่งประกอบกิจการโดย บริษัท สมิติเวช จำกัด (มหาชน)&nbsp;<br>
            โรงพยาบาลสมิติเวช ศรีราชา ซึ่งประกอบกิจการโดย บริษัท สมิติเวช ศรีราชา จำกัด&nbsp;<br>
            โรงพยาบาลสมิติเวช ธนบุรี ซึ่งประกอบกิจการโดย บริษัท ธนบุรี เมดิเคิล เซ็นเตอร์ จำกัด (มหาชน)&nbsp;<br>
            โรงพยาบาลสมิติเวช ชลบุรี ซึ่งประกอบกิจการโดย บริษัท สมิติเวช ชลบุรี จำกัด&nbsp;<br>
            โรงพยาบาลสมิติเวช ไชน่าทาวน์ ซึ่งประกอบกิจการโดย บริษัท กรุงเทพดุสิตเวชการ จำกัด (มหาชน)&nbsp;</p>
            </p>

          </div>
        </div>
      </div>
    </div>
  </div>`,
    term: `
    <div style="height:50vh">
    <p>
    ข้อกำหนดและเงื่อนไขการใช้บริการโปรแกรมตรวจสุขภาพเฉพาะบุคคล (Digital Total Health Solution) (“บริการ DTHS”) นี้ ทำขึ้นระหว่าง บริษัท สมิติเวช จำกัด (มหาชน) ซึ่งต่อไปนี้เรียกว่า “ผู้ให้บริการ” ฝ่ายหนึ่ง กับผู้ใช้บริการ ซึ่งต่อไปนี้เรียกว่า “ผู้ใช้บริการ” อีกฝ่ายหนึ่ง โดยบริการ DTHS เป็นบริการตรวจประเมินความเสี่ยงเบื้องต้นต่อการเกิดโรค เช่น โรคหัวใจและหลอดเลือด โรคเบาหวาน โรคมะเร็งลำไส้ใหญ่ เป็นต้น โดยวิเคราะห์ข้อมูลจากจากผลการประเมินความเสี่ยง อายุ กับเพศของผู้ใช้บริการ เพื่อนำผลการวิเคราะห์นั้นมาจัดหาโปรแกรมการตรวจสุขภาพประจำปีที่ตรงกับสภาพร่างกายของของผู้ใช้บริการ</p>
   <p>เมื่อผู้ใช้บริการได้เข้าใช้บริการ DTHS ผู้ใช้บริการตกลงที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขการใช้บริการนี้ ดังต่อไปนี้ </p>

    <p><strong>1. คำจำกัดความ </strong></p>
    <p>
    1.1 “บริการ DTHS” หมายถึง บริการโปรแกรมตรวจสุขภาพเฉพาะบุคคล (Digital Total Health Solution) ที่ให้บริการผ่านเว็บไซต์ของผู้ให้บริการ https://www.samitivejhospitals.com/th/package/detail/digital-total-health-solution </br>
    1.2 “ผู้ใช้บริการ” หมายถึง ผู้เข้าใช้บริการ และตอบแบบสอบถามของบริการ DTHS ผ่านเว็บไซต์ของผู้ให้บริการ  </br>
    1.3 “ผู้ให้บริการ” หมายถึง บริษัท สมิติเวช จำกัด (มหาชน) </br>
    </p>
    <p><strong>2. การใช้บริการ DTHS </strong></p>
    <p>
    ผู้ใช้บริการรับทราบ ยอมรับและตกลงกับผู้ให้บริการ ดังนี้ </br>
    2.1 ผู้ใช้บริการต้องทำแบบสอบถามด้านสุขภาพ เพื่อคัดกรองความเสี่ยงสุขภาพเบื้องต้น และกรอกข้อมูลส่วนตัวเพื่อเป็นช่องทางการติดต่อ สำหรับแจ้งผลการวิเคราะห์ความเสี่ยง, นำเสนอโปรแกรมการตรวจสุขภาพประจำปีที่ตรงกับสภาพร่างกายของของผู้ใช้บริการ และนัดหมายเข้ารับบริการตรวจสุขภาพ ซึ่งการทำแบบสอบถามและกรอกข้อมูลส่วนตัวนั้นให้ถือว่าเป็นการกระทำของผู้ใช้บริการเอง และมีผลผูกพันต่อผู้ใช้บริการ </br>
    2.2  ผู้ใช้บริการรับทราบว่าคำศัพท์เทคนิคทางการแพทย์ที่เกี่ยวกับการวินิจฉัยโรคและหัตถการนั้น เป็นคำศัพท์ภาษาอังกฤษและยากต่อการทำความเข้าใจของผู้ใช้บริการ หากผู้ใช้บริการมีข้อสงสัยเกี่ยวกับข้อมูลการวินิจฉัยโรค การรักษา หรือหัตถการ โปรดติดต่อสอบถามผู้ให้บริการ </br>
    2.3 หากมีความผิดพลาดเกิดขึ้นเกี่ยวกับการใช้บริการ หรือมีข้อสงสัยเกี่ยวกับข้อมูลสุขภาพและอื่นๆ ผู้ใช้บริการจะต้องแจ้งรายละเอียดต่างๆ ที่เกี่ยวข้องให้แก่ผู้ให้บริการ เพื่อให้ผู้ให้บริการใช้เป็นข้อมูลในการตรวจสอบข้อผิดพลาด </p>

    <p><strong>3. ความรับผิดและข้อยกเว้นความรับผิดของผู้ให้บริการ </strong></p>
    <p>
    ผู้ให้บริการจะรับผิดชอบสำหรับความเสียหายที่เกิดขึ้นในกรณีที่ผู้ให้บริการกระทำโดยจงใจ หรือประมาทเลินเล่ออย่างร้ายแรง ซึ่งเป็นความเสียหายที่ตามปกติย่อมเกิดขึ้นเท่านั้น  แต่ผู้ให้บริการไม่ต้องรับผิดชอบในความเสียหายที่เกิดขึ้นจากพฤติการณ์พิเศษ  ทั้งนี้ ผู้ใช้บริการตกลงว่า ไม่ว่าในกรณีใดๆ ผู้ให้บริการไม่ต้องรับผิดชอบในความเสียหายที่เกิดขึ้นจากสาเหตุต่างๆ ดังต่อไปนี้ </br>
    3.1 เหตุสุดวิสัยต่างๆ หรือเหตุการณ์ที่ผู้ให้บริการไม่สามารถควบคุมได้ ซึ่งรวมถึงการที่อุปกรณ์ ระบบสื่อสาร หรือการส่งสัญญาณเชื่อมต่อไม่ทำงาน หรือถูกรบกวนจากไวรัสคอมพิวเตอร์ หรือจากการกระทำที่ไม่ชอบด้วยกฎหมาย หรือในกรณีที่ผู้ให้บริการปิดการให้บริการ เพื่อปรับปรุงหรือบำรุงรักษาระบบ อันเป็นเหตุให้ผู้ใช้บริการไม่สามารถใช้บริการได้ </br>
    3.2 ความล่าช้า ความผิดพลาด หรือความบกพร่องของระบบคอมพิวเตอร์ หรืออุปกรณ์ต่างๆ ของผู้ใช้บริการ ผู้ให้บริการ หรือผู้ให้บริการระบบอินเทอร์เน็ต (Internet Service Provider) หรือเครือข่ายการสื่อสารอื่น เว้นแต่ในกรณีที่ความล่าช้า ความผิดพลาด หรือความบกพร่องดังกล่าว เกิดจากความจงใจหรือประมาทเลินเล่ออย่างร้ายแรงของผู้ให้บริการ </br>
    3.3 ความล่าช้าในขั้นตอนการดำเนินการของบริการ DTHS </br>
    3.4 การที่ผู้ใช้บริการไม่ปฏิบัติตามข้อกำหนดและเงื่อนไขของบริการ
    </p>

    <p><strong>4. ข้อมูลการติดต่อ และการส่งคำบอกกล่าว </strong></p>
    <p>
    4.1 ผู้ใช้บริการตกลงให้ผู้ให้บริการติดต่อสอบถาม เก็บ รวบรวม หรือ ใช้ข้อมูลบางประการหรือทั้งหมดเกี่ยวกับผู้ใช้บริการ และที่เกี่ยวกับการให้บริการได้ ตามความจำเป็นและเหมาะสม  </br>
    4.2 หนังสือ จดหมาย คำบอกกล่าวใดๆ ของผู้ให้บริการที่จัดส่งให้แก่ผู้ใช้บริการทางไปรษณีย์ หรือไปรษณีย์อิเล็กทรอนิกส์ (E-mail) ตามที่อยู่ซึ่งผู้ใช้บริการได้แจ้งให้ผู้ให้บริการทราบ ให้ถือว่า เป็นการจัดส่งให้แก่ผู้ขอใช้บริการโดยชอบแล้ว และในกรณีที่ผู้ใช้บริการเปลี่ยนแปลงที่อยู่ดังกล่าว ผู้ใช้บริการจะแจ้งให้ผู้ให้บริการทราบเป็นลายลักษณ์อักษร
    </p>

    <p><strong>5. การแก้ไขเพิ่มเติม หรือยกเลิกการให้บริการ </strong></p>
    <p>
    การให้บริการตามข้อกำหนดนี้ เป็นการให้บริการตามสภาพที่เป็นอยู่ (AS IS) และมีอยู่ (AS AVAILABLE) ผู้ให้บริการสงวนสิทธิ์ที่จะเปลี่ยนแปลง จำกัด หรือระงับการเข้าถึง หรือยกเลิกการให้บริการนี้ในเวลาใด ๆ ก็ได้ โดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า</p>
    <p>ผู้ให้บริการอาจแก้ไขเพิ่มเติมข้อกำหนดและเงื่อนไขการใช้บริการนี้ในเวลาใดๆ ก็ได้ และในการที่ผู้ใช้บริการยังคงใช้บริการนี้ต่อไป ให้ถือว่าท่านรับทราบและตกลงผูกพันตามการแก้ไข โดยผู้ใช้บริการมีหน้าที่ติดตามการแก้ไขเปลี่ยนแปลงดังกล่าว และจะไม่อ้างเหตุในการไม่ทราบถึงการแก้ไขเพิ่มเติมข้อกำหนดและเงื่อนการใช้บริการมาเป็นเหตุในการฝ่าฝืนข้อกำหนดและเงื่อนไขนี้ได้
    </p>
    <p><strong>6. กฎหมายที่ใช้บังคับ </strong></p>
    <p>
    หากมีข้อพิพาทใดเกิดขึ้นจากการให้บริการของผู้ให้บริการ อันเนื่องมาจากบริการนี้ ผู้ใช้บริการและผู้ให้บริการตกลงใช้กฎหมายไทยในการระงับข้อพิพาท </p>
    </div>

    `,
    //  noted for thai people
    forThai:
      "* สงวนสิทธิ์ราคาพิเศษสำหรับชาวไทยและชาวต่างชาติที่อาศัยอยู่ในประเทศไทยเท่านั้น",
    forThai2: "และต้องจองล่วงหน้าอย่างน้อย 3 วันก่อนเข้ารับบริการ",
    // flash sale remark
    flashSaleRemark: ` <p>หมายเหตุ : เมื่อทำการจองแพ็กเกจเสร็จสมบูรณ์ ท่านจะได้รับคูปองส่วนลด 5%<br>
    ซึ่งจะถูกแนบมากับอีเมลยืนยันการจองแพ็กเกจ โปรดนำคูปองนี้ไปแสดง <br>
    ที่เคาน์เตอร์ชำระเงิน ณ โรงพยาบาลที่ท่านใช้บริการเพื่อรับส่วนลด 5% <br>
    สอบถามเพิ่มเติม กรุณาติดต่อ Line Official Account : @Wellbysamitivej</p>`,
    flashSaleAds: "12.12 Flash Sale",
    flashSaleAds2: "ลด 5% ทุกแพ็กเกจ",
    recommendedPackages: "แพ็กเกจแนะนำ",
  },
};

const i18n = createI18n({
  locale: "th",
  messages: messages,
});

export default i18n;
// import { createI18n } from "vue3-i18n";
// import enLocale from "./app-en";
// import thLocale from "./app-th";

// const messages = {
//   en: enLocale,
//   th: thLocale,
// };

// const i18n = createI18n({
//   locale: "en",
//   messages: messages,
// });

// export default i18n;
