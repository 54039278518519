import axios from "axios";
import { createStore } from "vuex";

export const store = new createStore({
  state: {
    userPool: [],
    cognitoUser: "",
    username: "",
    authenticated: false,
    site: "",
    associatedPackages: {},
    access_token: "",
    lang: "th",
  },
  getters: {
    getAssociatedPackages: (state) => (hospitalPackageId) => {
      return state.associatedPackages[hospitalPackageId] ?? [];
    },
  },
  mutations: {
    selectAssociatePackage(state, payload) {
      const currentPackage = state.associatedPackages[
        payload.mainHospitalPackageId
      ].find(
        (item) =>
          item.hospitalPackageId == payload.associatePackage.hospitalPackageId
      );
      if (currentPackage) {
        currentPackage.select = !currentPackage.select;
      }
    },
    setAssociatedPackages(state, payload) {
      state.associatedPackages[payload.hospitalPackageId] =
        payload.associatedPackages;
    },
    setSite(state, site) {
      state.site = site;
    },
    setAccessToken(state, access_token) {
      state.access_token = access_token;
    },
    getAuthenticatedUser(state) {
      state.userPool.getCurrentUser();
    },
    signOut(state) {
      this.getAuthenticatedUser().signOut();
      state.authenticated = false;
      state.username = "";
      state.cognitoUser = "";
      state.userPool = [];
      console.log("store: signed out");
    },
    isAuthenticated(state) {
      const user = this.getAuthenticatedUser();
      user.getSession((err, session) => {
        if (err) {
          console.log(err);
          state.authenticated = false;
        } else {
          if (session.isValid()) {
            console.log(session);
            state.authenticated = true;
          }
        }
      });
    },
  },
  actions: {
    async getAssociatedPackages(store, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_TOTALHEALTHAPI_URL}/hospital-package/get-package-by-ids`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${store.state.access_token}`,
              accept: "application/json; charset=UTF-8",
              "Accept-Language": store.state.lang,
              "Content-Type": "application/json",
            },
          }
        );
        if (response) {
          const hospitalPackageBean = response.data[0].hospitalPackageBean;
          const mainPackage = hospitalPackageBean.mainPackage;
          const associatedPackages = hospitalPackageBean.associatedPackages;
          store.commit("setAssociatedPackages", {
            hospitalPackageId: mainPackage.hospitalPackageId,
            associatedPackages: associatedPackages,
          });
        } else {
          throw new Error("No response from server");
        }
      } catch (e) {
        if (process.env.VUE_APP_RUN_MODE === "dev") console.error(e);
      }
    },
  },
});
