
import { defineComponent } from "vue";
import { datadogRum } from "@datadog/browser-rum";

export default defineComponent({
  name: "App",
  // data() {
  //   return {
  //     lang: "en",
  //   };
  // },
  mounted() {
    if (process.env.VUE_APP_RUN_MODE !== "dev") this.initDatadog();
  },
  methods: {
    initDatadog() {
      datadogRum.init({
        applicationId: "3b2917d1-93dd-40d7-9146-2c01d27b5405",
        clientToken: "pub3f600701c0910f3eb675c4f00767d249",
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: "ap1.datadoghq.com",
        service: "web-total-health-solution",
        env: process.env.VUE_APP_RUN_MODE,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: "1.1.0",
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "allow",
      });
    },
  },
});
